import { AgxLabel, AgxRow } from '@urbanx/agx-ui-components';
import clsx from 'clsx';
import './SegmentedPicker.scss';

const SegmentedPicker = ({ segments, id }) => {
  return (
    <AgxRow extraClasses="segmentedPicker">
      {segments.map((segment, idx) => {
        const { title, mobileTitle, isSelected, onSelected } = segment;

        let mobileTitleText = mobileTitle ?? title;

        return (
          <Segment
            id={`${id}-${idx}`}
            key={idx}
            title={title}
            mobileTitle={mobileTitleText}
            isSelected={isSelected}
            onClick={onSelected}
          />
        );
      })}
    </AgxRow>
  );
};

const Segment = ({ title, mobileTitle, isSelected, onClick, id }) => {
  const classes = clsx('segmentPicker', isSelected && 'selected');

  return (
    <div className={classes} onClick={onClick}>
      <AgxLabel medium extraClasses={classes} dataTestId={id}>
        <span className="title" data-testid={`${id}-title`}>
          {title}
        </span>
        <span className="mobileTitle" data-testid={`${id}-mobile-title`}>
          {mobileTitle}
        </span>
      </AgxLabel>
    </div>
  );
};

export default SegmentedPicker;
