import { Configuration, LogLevel } from '@azure/msal-browser';
import { b2cPolicies } from './policies';
import { AUTH } from 'constants/AUTH';

export const authConfig = (): Configuration => {
  return {
    auth: {
      clientId: AUTH.CLIENT_ID || '',
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorityDomain || ''],
      redirectUri: import.meta.env.VITE_REACT_APP_ENV_SIGNIN_PREFIX,
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          const shouldLog = import.meta.env.VITE_REACT_APP_LOG_AUTH === 'true';
          if (!shouldLog) return;
          if (containsPii) {
            return;
          }

          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        },
      },
    },
  };
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['openid'],
};
