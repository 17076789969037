import { EntityTimePeriodStatistics } from '@urbanx/agx-ui-components/dist/types/components/portal/Reporting/types';
import StatCard from '../StatCard/StatCard';

interface EntityStatisticsProps {
  loading: boolean;
  personalStatistics: EntityTimePeriodStatistics | null;
}

const EntityStatistics = ({
  loading,
  personalStatistics,
}: EntityStatisticsProps) => {
  const noData = !loading && !personalStatistics;

  return (
    <div className="reportCards">
      <StatCard
        data={[
          {
            title: 'Sales Volume',
            value: personalStatistics?.salesVolume ?? 0,
            goal: personalStatistics?.amountSalesVolumeAboveTarget,
            isCurrency: true,
          },
        ]}
        bold
        loading={loading}
        noData={noData}
        subText={personalStatistics?.salesVolumeSubText}
      />
      <StatCard
        data={[
          {
            title: 'GCI',
            value: personalStatistics?.grossCommissionIncome ?? 0,
            goal: personalStatistics?.amountGrossCommissionIncomeAboveTarget,
            isCurrency: true,
          },
        ]}
        bold
        loading={loading}
        noData={noData}
        subText={personalStatistics?.grossCommissionIncomeSubText}
      />
      <StatCard
        data={[
          {
            title: 'Listings',
            value: personalStatistics?.listings ?? 0,
            goal: personalStatistics?.amountListingsAboveTarget,
          },
          {
            title: 'Sales',
            value: personalStatistics?.sales ?? 0,
            goal: personalStatistics?.amountSalesAboveTarget,
          },
          {
            title: 'Conversion rate',
            value: (personalStatistics?.conversionRate ?? 0) * 100,
            goal: personalStatistics?.amountConversionRateAboveTarget,
            isPercentage: true,
          },
        ]}
        loading={loading}
        noData={noData}
        centerJustified={false}
      />
      <StatCard
        data={[
          {
            title: 'Average Sale Price',
            value: personalStatistics?.averageSalePrice ?? 0,
            goal: personalStatistics?.amountAverageSalePriceAboveTarget,
            isCurrency: true,
          },
          {
            title: 'Average Comm Rate',
            value: personalStatistics?.averageCommissionRate ?? 0,
            goal: personalStatistics?.amountAverageCommissionRateAboveTarget,
            isPercentage: true,
          },
        ]}
        loading={loading}
        noData={noData}
        centerJustified={false}
      />
    </div>
  );
};

export default EntityStatistics;
