import {
  AgxColumn,
  AustralianState,
  FormType,
  SubmitType,
} from '@urbanx/agx-ui-components';
import AgencyAgreementGenerating from './AgencyAgreement/AgencyAgreementGenerating';
import AgencyAgreementSubmissionGenerating from './AgencyAgreementSubmission/AgencyAgreementSubmissionGenerating';
import SalesContractGenerating from './SalesContract/SalesContractGenerating';
import SalesContractSubmissionGenerating from './SalesContractSubmission/SalesContractSubmissionGenerating';
import GenerationStatus from './GenerationStatus';
import './GeneratingPage.scss';

interface GeneratingPageProps {
  generationStatus: GenerationStatus;
  formType: FormType;
  submitType: SubmitType;
  state: AustralianState;
}

const GeneratingPage = ({
  generationStatus,
  formType,
  submitType,
  state,
}: GeneratingPageProps) => {
  const getGeneratingPage = (formType: FormType) => {
    switch (formType) {
      case FormType.MarketingPackage:
      case FormType.ListingGenerator:
        return (
          <AgencyAgreementGenerating
            generationStatus={generationStatus}
            submitType={submitType}
          />
        );
      case FormType.ListingSubmission:
        return (
          <AgencyAgreementSubmissionGenerating
            generationStatus={generationStatus}
          />
        );
      case FormType.ContractRequest:
        return (
          <SalesContractGenerating
            generationStatus={generationStatus}
            submitType={submitType}
            state={state}
          />
        );
      case FormType.ContractSubmission:
        return (
          <SalesContractSubmissionGenerating
            generationStatus={generationStatus}
          />
        );
      default:
        return <span>not implemented</span>;
    }
  };

  return (
    <div className="agxFormGenerationPage">
      <AgxColumn fill centerJustified centered largeGap>
        {getGeneratingPage(formType)}
      </AgxColumn>
    </div>
  );
};

export default GeneratingPage;
