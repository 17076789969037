import { useEffect, useMemo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { useAppSelector } from 'hooks/useAppSelector';
import RPDataAuthenticated from './features/elements/Authentication/RpDataAuthenticated';
import DocuSignAuthenticated from './features/elements/Authentication/DocuSignAuthenticated';
import { Popup } from './components/ui-components';
import useAuth from 'hooks/useAuth';
import { AuthenticatedLayout } from './layout/AuthenticatedLayout';
import CampaignsViewPage from './features/elements/CampaignsView/CampaignsViewPage';
import Form from './features/form/form';
import { AgxToast } from '@urbanx/agx-ui-components';
import { Widget } from './components/freshDeskWidget/freshDeskWidget';
import LogRocket from 'logrocket';
import BrandedLayout from './layout/BrandedLayout';
import ReportingDashboard from './features/reportingDashboard/reportingDashboard';
import ValidateAccessLayout from './layout/ValidateAccessLayout';
import './App.scss';
import 'animate.css';
import '@urbanx/agx-ui-components/dist/index.css';

function App() {
  const { msalInstance } = useAuth();
  const { toast: toastState } = useAppSelector(state => state.config);

  const isLocal = useMemo(() => {
    return window.location.hostname === 'localhost';
  }, [window.location.hostname]);

  useEffect(() => {
    if (isLocal) return;
    LogRocket.init('dzljjh/autopilot');
  }, []);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <AuthenticatedLayout />,
      children: [
        {
          path: '/',
          element: (
            <ValidateAccessLayout>
              <BrandedLayout>
                <CampaignsViewPage />
              </BrandedLayout>
            </ValidateAccessLayout>
          ),
        },
        {
          path: '/form',
          element: <Form />,
        },
        {
          path: '/rpdata/authenticated',
          element: <RPDataAuthenticated />,
        },
        {
          path: '/docusign/authenticated',
          element: <DocuSignAuthenticated />,
        },
        {
          path: '/dashboard',
          element: (
            <BrandedLayout>
              <ReportingDashboard />
            </BrandedLayout>
          ),
        },
      ],
    },
  ]);

  return (
    <MsalProvider instance={msalInstance}>
      <AgxToast selector="#toast" toastState={toastState} />
      <Popup selector="#popup" />
      <RouterProvider router={router} />
      {!isLocal && <Widget />}
    </MsalProvider>
  );
}

export default App;
