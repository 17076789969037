import React, { useEffect, useState } from 'react';
import {
  AgxColumn,
  AgxDivider,
  AgxHeader,
  AgxButton,
  FormType,
  CampaignDetailModel,
  CampaignStage,
  EnvelopeSubmissionStatus,
  EnvelopeSubmissionType,
  AgxRow,
  AgxLabel,
  SPECIAL_CHARACTERS,
  addDividersBetweenElements,
} from '@urbanx/agx-ui-components';
import moment from 'moment';
import { cleanTwoLineAddress } from 'utils/formatAddress';
import { SentForSigningButtons } from '../../CampaignsList/components/SentForSigningPanel/SentForSigningButtons';
import { Signatory } from '../../CampaignsList/components/SentForSigningPanel/Signatory';
import FormDetailsHeader from '../Common/FormDetailsHeader';
import placeHolderImage from 'assets/images/placeholder-property.png';
import useStateFormLabels from '../../../../../form/FormLabels/useStateFormLabels';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { Tabs } from 'types/Tabs';
import './AgencyAgreementSigningPanel.scss';
import { useFormLoader } from 'hooks/useFormLoader';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { setAndShowErrorToast } from 'store/config';
import { completeCampaignStep } from 'Api/Campaigns/campaignsApi';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { getEnumValue } from 'helpers/enumHelper';
import isEqual from 'lodash/isEqual';

interface AgencyAgreementSigningPanelProps {
  campaign: CampaignDetailModel;
  currentTab: Tabs;
  mobile: boolean;
}

const AgencyAgreementSigningPanel: React.FC<
  AgencyAgreementSigningPanelProps
> = ({ campaign, currentTab }) => {
  const {
    agencyAgreementSubmission: envelopeSubmission,
    propertyImageUrls: { medium: imageUrl = null } = {},
    address,
    campaignId,
    completionState,
    stage,
  } = campaign;
  const {
    formDocumentSubmissionId: envelopeId,
    type,
    recipients: signatories,
    createdAt,
  } = envelopeSubmission ?? {};

  const declinedStatus = signatories?.find(
    recipient => recipient.status === EnvelopeSubmissionStatus.Declined
  );

  const isMobile = ScreenSize() === Breakpoints.Mobile;
  const isDesktop = ScreenSize() === Breakpoints.Desktop;

  const formName = useStateFormLabels(campaign.state, 'formName');
  const availableForms = useAppSelector(state => state.forms.availableForms);
  const { loadFormWithConfig } = useFormLoader();
  const [, getAuthToken] = useAzureAuth();
  const dispatch = useAppDispatch();

  const invalidSubmissionStage = () =>
    getEnumValue(CampaignStage, stage) !==
    CampaignStage.AgreementSignedByAllParties;

  const [disableInputs, setDisableInputs] = useState(invalidSubmissionStage);

  useEffect(() => {
    setDisableInputs(invalidSubmissionStage);
  }, [stage]);

  const submissionTypeText = (
    type: EnvelopeSubmissionType | null | undefined
  ) => {
    switch (type) {
      case EnvelopeSubmissionType.Manual:
        return 'Manual Submission';
      case EnvelopeSubmissionType.DocuSign:
        return 'DocuSign';
      case EnvelopeSubmissionType.Email:
        return 'Email';
      default:
        return '';
    }
  };

  const headerDetail = `Sent via ${submissionTypeText(type)} on ${moment(createdAt).format('DD/MM/YY')}`;

  const [addressLineOne, addressLineTwo] = cleanTwoLineAddress(address);

  const beginAgencyAgreementSubmission = async () => {
    const inProgressForm =
      campaign.inProgressForms != null ? campaign.inProgressForms[0] : null;

    if (!campaignId || !inProgressForm?.formId) return;

    const agencyAgreementSubmissionForm = availableForms.find(
      form =>
        form.type === FormType.ListingSubmission &&
        isEqual(form.state, campaign.state)
    );

    if (!agencyAgreementSubmissionForm) {
      dispatch(
        setAndShowErrorToast('Failed to start Agency Agreement submission')
      );
      return;
    }

    try {
      const authToken = await getAuthToken();

      if (!authToken) {
        setDisableInputs(false);
        return;
      }

      const newForm = await completeCampaignStep(
        authToken,
        campaignId,
        false,
        inProgressForm?.formId
      );

      if (!newForm) {
        dispatch(
          setAndShowErrorToast('Failed to start Agency Agreement submission')
        );
        setDisableInputs(false);
        return;
      }

      await loadFormWithConfig(newForm);
    } catch (err) {
      dispatch(
        setAndShowErrorToast('Failed to start Agency Agreement submission')
      );
    }

    setDisableInputs(false);
  };

  const renderSignatories = () => {
    const signatoryComponents = signatories?.map((signatory, i) => {
      if (signatory && envelopeId) {
        return (
          <Signatory
            key={`signatory-${i}`}
            signatory={signatory}
            envelopeSubmissionType={type}
            envelopeId={envelopeId}
            declinedStatus={!!declinedStatus}
          />
        );
      }

      return <></>;
    });

    if (!signatoryComponents) return <></>;

    return [
      <AgxDivider key="initialDivider" />,
      ...addDividersBetweenElements(signatoryComponents, false, false),
    ];
  };

  const StartSubmissionButton = () => {
    return (
      <div
        className="campaignModalButtons"
        data-testid={`agx-startSubmissionButton`}
      >
        <AgxButton
          primary
          large={isDesktop || isMobile}
          medium={!isDesktop && !isMobile}
          text="Submit Agreement"
          onClick={beginAgencyAgreementSubmission}
          disabled={
            disableInputs ||
            !!completionState?.completionDate ||
            currentTab === Tabs.Archive
          }
        />
      </div>
    );
  };

  return (
    <>
      <FormDetailsHeader
        id={'aa-signing-panel-header'}
        formName={formName}
        currentTab={currentTab}
        detail={headerDetail}
        onBack={undefined}
      />
      <AgxColumn veryLargeGap fill centered>
        <AgxHeader size={2} centered>
          {addressLineOne},
          <br />
          {addressLineTwo}
        </AgxHeader>
        <AgxRow centered justifyCenter extraClasses={'listing-imageCard'}>
          <div
            style={{
              backgroundImage: `url("${imageUrl || placeHolderImage}")`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              aspectRatio: '2/1',
            }}
            className={`imageCard-image ${completionState.completionType ? 'archived' : ''}`}
          >
            <div className="image-tag">
              <AgxLabel medium colour="brand-text-button-color">
                {
                  CampaignStage[
                    campaign.stage as unknown as keyof typeof CampaignStage
                  ].split(SPECIAL_CHARACTERS.BULLET)[1]
                }
              </AgxLabel>
            </div>
          </div>
        </AgxRow>
        <div className="agencyAgreementSigningPanelButtons">
          {envelopeId && (
            <SentForSigningButtons
              currentTab={currentTab}
              campaign={campaign}
              envelopeId={envelopeId}
              isMobile={isMobile}
              isDesktop={isDesktop}
            />
          )}
          {!isMobile && <StartSubmissionButton />}
        </div>
        <AgxColumn fill centered extraClasses={'signatoriesContainer'}>
          {renderSignatories()}
        </AgxColumn>
      </AgxColumn>

      {isMobile && <StartSubmissionButton />}
    </>
  );
};

export default AgencyAgreementSigningPanel;
