import clsx from 'clsx';
import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  filterDisplayElementHideCondition,
  filterPageHideCondition,
  useDeepMemo,
  PageStatusType,
  SubmitType,
  addDividersBetweenElements,
  FormConfigType,
} from '@urbanx/agx-ui-components';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useNavigate } from 'react-router-dom';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { campaignsApi } from 'Api/Campaigns/campaignsApi';
import { FormHeader } from './FormHeader/FormHeader';
import {
  updateFormErrors,
  updatePageStatuses,
  clearGeneratedFormDocuments,
} from './formReducer';
import { useElementRenderer } from 'hooks/useElementRenderer';
import { DesktopHeader } from './DesktopHeader/DesktopHeader';
import { DesktopSidebar } from './DesktopSidebar/DesktopSidebar';
import { useFormSettings } from 'hooks/useFormSettings';
import { useFormClearer } from 'hooks/useFormCleaner';
import GeneratingPage from './TransitionPages/GeneratingPage';
import GenerationStatus from './TransitionPages/GenerationStatus';
import FormButtons from './FormButtons/FormButtons';
import { delay } from 'utils/delay';
import {
  markCampaignsAsStale,
  reloadCampaigns,
} from '../campaigns/campaignsReducer';
import { useToaster } from 'hooks/useToaster';
import ErrorModal from 'helpers/ErrorModal';
import { usePreloadRenderer } from 'hooks/usePreloadRenderer';
import BeginCampaign from '../elements/BeginCampaign/BeginCampaign';
import { useFormSaver } from 'hooks/useFormSaver';
import './form.scss';
import { submitMarketingPackage } from 'Api/Marketing/marketingApi';

const Form = () => {
  const [localPageStatuses, setLocalPageStatuses] = useState([]);
  const [, getAuthToken] = useAzureAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clearFormData = useFormClearer();
  const { showErrorToast } = useToaster();
  const updateFormSettings = useFormSettings();
  const saveFormChanges = useFormSaver();

  const {
    selectedForm,
    formId,
    campaignId,
    formPages,
    formValues: formData,
    formErrors: errors,
    pageStatuses,
    generatedFormDocuments,
    generatedDocumentTypes,
    address: formAddress,
  } = useAppSelector(state => state.form);

  const { formType, state, formConfigType } = selectedForm || {};

  const [currentPageNum, setCurrentPageNum] = useState(0);
  const pages = useDeepMemo(
    () => filterPageHideCondition(formPages, formData),
    [formData, formPages]
  );

  const currentPage = useMemo(
    () => pages[currentPageNum],
    [pages, currentPageNum]
  );

  const {
    listingAddress,
    showBreadcrumbs,
    showListingDetails,
    displayTitle,
    displayTitleVisible,
    formBackgroundInverted,
    viewPreFill,
    growContent = false,
    noGap = false,
  } = useAppSelector(state => state.formSettings);

  const [openErrorModal, setOpenErrorModal] = useState(false);

  const [generatingStatus, setGeneratingStatus] = useState(
    GenerationStatus.NotGenerating
  );
  const [validatingForm, setValidatingForm] = useState(false);

  const isValidPreviousPage = targetPageNumber => {
    const pageIndex = pages.findIndex(page => page?.name === currentPage?.name);
    if (targetPageNumber < pageIndex) return true;
    return false;
  };

  const isValidNextPage = targetPageNumber => {
    const currentPageIndex = pages.findIndex(
      page => page.name === currentPage.name
    );
    if (targetPageNumber >= pages.length) return true;

    // if pageStatuses is null, move one page at a time
    if (pageStatuses === null || currentPageIndex + 1 === targetPageNumber) {
      return true;
    }

    const targetPageName = pages[targetPageNumber].name;
    const targetPageStatus = pageStatuses?.find(
      page => page.pageName === targetPageName
    )?.status;

    // should be able to navigate to completed pages
    if (targetPageStatus === PageStatusType.Complete) {
      return true;
    }

    // target page is the next available page
    if (targetPageNumber > 0) {
      const oneBeforeTargetPageIndex = targetPageNumber - 1;
      const oneBeforeTargetPageName = pages[oneBeforeTargetPageIndex].name;
      const oneBeforeTargetPageStatus = pageStatuses?.find(
        page => page.pageName === oneBeforeTargetPageName
      )?.status;
      if (
        oneBeforeTargetPageStatus &&
        oneBeforeTargetPageStatus === PageStatusType.Complete
      ) {
        return true;
      }
    }

    return false;
  };

  const validateCurrentPage = async targetPageNumber => {
    setValidatingForm(true);
    const isPreviousPage = isValidPreviousPage(targetPageNumber);
    const isNextPage = isValidNextPage(targetPageNumber);
    await delay(30);
    const elementsWithErorr = document.getElementsByClassName('error');

    if (elementsWithErorr.length > 0) {
      elementsWithErorr[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    const fileIsUploading = document.getElementsByClassName('uploadProgress');
    if (fileIsUploading.length > 0) {
      fileIsUploading[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    // update the page status to complete if validation is success
    const existingPageStatus = localPageStatuses.filter(
      page => page.pageName !== currentPage.name
    );
    const pageStatus = {
      status: PageStatusType.Invalid,
      pageName: currentPage.name,
    };

    if (elementsWithErorr.length !== 0 && fileIsUploading.length !== 0) {
      pageStatus.status = PageStatusType.Invalid;
      setLocalPageStatuses([...existingPageStatus, pageStatus]);
      dispatch(
        updatePageStatuses({
          pageStatuses: [...existingPageStatus, pageStatus],
        })
      );
    }

    if (
      isNextPage &&
      elementsWithErorr.length === 0 &&
      fileIsUploading.length === 0
    ) {
      pageStatus.status = PageStatusType.Complete;
      setLocalPageStatuses([...existingPageStatus, pageStatus]);
      dispatch(
        updatePageStatuses({
          pageStatuses: [...existingPageStatus, pageStatus],
        })
      );
    }

    return {
      isPreviousPage,
      isNextPage,
      status: elementsWithErorr.length === 0 && fileIsUploading.length === 0,
      pageStatuses: [...existingPageStatus, pageStatus],
    };
  };

  const updateCurrentPage = async pageNum => {
    if (pageNum >= 0 && pageNum < pages.length) {
      const validationResult = await validateCurrentPage(pageNum);
      if (validationResult.status) {
        if (validationResult.isPreviousPage || validationResult.isNextPage) {
          setCurrentPageNum(pageNum);
        }
      }
    }
  };

  const goToPreviousPage = async () => {
    const validationResult = await validateCurrentPage(currentPageNum - 1);
    if (validationResult.status) {
      // reset validation
      setValidatingForm(false);

      const nextPage = currentPageNum - 1;

      if (nextPage >= 0) {
        setCurrentPageNum(nextPage);
      }
    }
  };

  const goToNextPage = async () => {
    const validationResult = await validateCurrentPage(currentPageNum + 1);
    if (validationResult.status) {
      // reset validation
      setValidatingForm(false);

      const nextPage = currentPageNum + 1;

      if (nextPage < pages.length) {
        setCurrentPageNum(nextPage);
      }
    }
  };

  const goToFirstPage = () => {
    setCurrentPageNum(0);
  };

  const saveForm = async () => {
    if (campaignId == null || formId == null || formType == null) return;

    await saveFormChanges(formConfigType);
  };

  const renderElements = useElementRenderer({
    goToNextPage,
    goToFirstPage,
    goToPreviousPage,
  });
  const renderPreloader = usePreloadRenderer();

  const submitFormForReview = async () => {
    const result = await validateCurrentPage(currentPageNum + 1);
    if (result.status) {
      setGeneratingStatus(GenerationStatus.Generating);

      try {
        const token = await getAuthToken();

        switch (formConfigType) {
          case FormConfigType.Campaign: {
            const {
              data: { errors },
            } = await campaignsApi(token).post('SubmitForm', {
              formType,
              formData: formData,
              formId: formId,
              campaignId,
            });

            dispatch(updateFormErrors(errors ?? []));
            dispatch(markCampaignsAsStale());
            dispatch(clearGeneratedFormDocuments());
            break;
          }
          case FormConfigType.Marketing: {
            const data = await submitMarketingPackage(
              token,
              campaignId,
              formId,
              formType,
              formData
            );
            break;
          }
          default: {
            break;
          }
        }

        if (currentPage.submitType === SubmitType.Send) {
          if (errors?.length > 0) {
            setOpenErrorModal(true);
            setGeneratingStatus(GenerationStatus.NotGenerating);
          } else {
            setGeneratingStatus(GenerationStatus.Generated);
            setTimeout(() => {
              exitForm(false);
            }, 2500);
          }
        }
      } catch (err) {
        showErrorToast(err.message);
        setGeneratingStatus(GenerationStatus.NotGenerating);
        return err.message;
      }
    }
  };

  const exitForm = async (callSaveForm = true) => {
    if (callSaveForm) {
      await saveForm();
    }

    clearFormData();
    navigate('/');
    dispatch(reloadCampaigns());
  };

  const renderElementsWithDividers = (elements, validatingForm) => {
    return addDividersBetweenElements(
      renderElements(elements, validatingForm),
      true,
      currentPageNum < pages.length - 1
    );
  };

  useEffect(() => {
    if (pageStatuses) setLocalPageStatuses(pageStatuses);
  }, [pageStatuses]);

  const pageChanged = useCallback(
    (pageNumber, saveChanges = true) => {
      updateFormSettings({
        displayTitleVisible: true,
      });

      if (generatingStatus === GenerationStatus.Generating)
        setGeneratingStatus(GenerationStatus.NotGenerating);
      if (pages != null && pages[pageNumber] != null) {
        const {
          invertedColours,
          componentManagedFormPage,
          hasBreadcrumbs,
          noGap,
        } = pages[pageNumber];
        if (!componentManagedFormPage) {
          updateFormSettings({
            formBackgroundInverted: invertedColours,
            showBreadcrumbs: hasBreadcrumbs,
            displayTitle: null,
            noGap: noGap,
          });
        }
      }

      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      if (campaignId && pages && saveChanges) {
        saveForm();
      }
    },
    [
      updateFormSettings,
      generatingStatus,
      setGeneratingStatus,
      pages,
      campaignId,
      saveForm,
    ]
  );

  useEffect(() => {
    pageChanged(currentPageNum);
  }, [currentPageNum]);

  useEffect(() => {
    setCurrentPageNum(0);
    if (currentPageNum === 0) {
      pageChanged(0, false);
    }
  }, [formId, formType, state]);

  useEffect(() => {
    if (!generatedFormDocuments) return;

    const formDocument = generatedFormDocuments.find(gd =>
      generatedDocumentTypes.includes(gd.documentType)
    );

    if (formDocument) {
      setGeneratingStatus(GenerationStatus.Generated);

      setTimeout(() => {
        setGeneratingStatus(GenerationStatus.NotGenerating);
        goToNextPage();
      }, 2500);
    }
  }, [generatedFormDocuments]);

  const elements = useDeepMemo(
    () => filterDisplayElementHideCondition(currentPage?.elements, formData),
    [currentPage, formData]
  );

  useEffect(() => {
    if (!formType || !state) exitForm(false);
  }, [formType, state]);

  const formClasses = clsx('agx-form', formBackgroundInverted && 'inverted');

  if (generatingStatus !== GenerationStatus.NotGenerating) {
    return (
      <GeneratingPage
        generationStatus={generatingStatus}
        formType={formType}
        submitType={currentPage?.submitType}
        state={state}
      />
    );
  }

  // This is here so that it fills the whole page when
  // breadcrumbs are not available
  const formPageClasses = clsx(
    'agxFormPage',
    !showBreadcrumbs && 'noBreadcrumbs'
  );

  const formBackgroundClasses = clsx(
    'formBackground',
    !showBreadcrumbs && 'noBreadcrumbs'
  );

  return (
    <div className={formBackgroundClasses}>
      <DesktopHeader onBackToCampaigns={exitForm} formId={formId} />
      {renderPreloader(pages)}
      <div className={formPageClasses}>
        {showBreadcrumbs &&
          <DesktopSidebar
            address={formData.PropertyAddress ?? formAddress}
            formTypeValue={formType}
            pages={pages}
            currentPage={currentPage}
            setPage={setCurrentPageNum}
            pageValidation={validateCurrentPage}
            updatePageValidationFlag={setValidatingForm}
          />}
        <div
          className={
            !formId ? 'formContent formContentPropertySearch' : 'formContent'
          }
        >
          {displayTitleVisible && formId && currentPage && (
            <FormHeader
              showListingDetails={showListingDetails}
              listingAddress={listingAddress ?? formAddress?.formattedAddress}
              showBreadcrumbs={showBreadcrumbs}
              displayTitle={displayTitle}
              viewPreFill={viewPreFill}
              currentPage={currentPage}
              pages={pages}
              setPage={updateCurrentPage}
              saveForm={saveForm}
              formTypeValue={formType}
            />
          )}
          {!formId ? (
            <BeginCampaign id="BeginCampaign" />
          ) : (
            <div className={formClasses}>
              <div
                className={clsx(
                  'agx-form-elements',
                  growContent && 'growContent',
                  noGap && 'noGap'
                )}
              >
                {renderElementsWithDividers(elements, validatingForm)}
              </div>
              {currentPage && (
                <FormButtons
                  actionButtonType={currentPage.actionButtonType}
                  currentPageNum={currentPageNum}
                  totalPageNum={pages.filter(p => p.hasBreadcrumbs).length}
                  goToPreviousPage={goToPreviousPage}
                  goToNextPage={goToNextPage}
                  reviewForm={submitFormForReview}
                />
              )}
              {openErrorModal && (
                <ErrorModal
                  errorList={errors}
                  setShowModal={setOpenErrorModal}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Form;
