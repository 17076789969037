import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from './store';
import { v4 as uuidv4 } from 'uuid';
import {
  Message,
  ToastState,
} from '@urbanx/agx-ui-components/dist/types/components/status/Toast/Toast';

interface Popup {
  open: boolean;
  type: string;
  color: string;
  title: string;
  body: string;
  onConfirm: Function | null;
  buttonText: {
    close: string;
    confirm: string;
  };
}

interface ToastConfigState {
  toast: ToastState;
  popup: Popup;
  proceedToNextPage: boolean;
  proceedToPrevPage: boolean;
}

const initialState = {
  toast: {
    open: false,
    color: 'error',
    message: '',
  },
  popup: {
    open: false,
    type: 'confirm',
    color: 'primary',
    title: '',
    body: '',
    onConfirm: null,
    buttonText: {
      close: '',
      confirm: '',
    },
  },
  proceedToNextPage: true,
  proceedToPrevPage: true,
} satisfies ToastConfigState as ToastConfigState;

const slice = createSlice({
  name: 'config',
  initialState: initialState,
  reducers: {
    setAndShowToastMessage: (
      state,
      action: PayloadAction<Partial<ToastState>>
    ) => ({ ...state, toast: { ...state.toast, ...action.payload } }),
    closeToast: state => ({ ...state, toast: { ...state.toast, open: false } }),
    setAndShowPopup: (state, action: PayloadAction<Popup>) => ({
      ...state,
      popup: action.payload,
    }),
    closePopupBox: (state, action: PayloadAction<boolean>) => ({
      ...state,
      popup: { ...state.popup, open: action.payload },
    }),
    setProceedToNextPage: (state, action: PayloadAction<boolean>) => ({
      ...state,
      proceedToNextPage: action.payload,
    }),
    setProceedToPrevPage: (state, action: PayloadAction<boolean>) => ({
      ...state,
      proceedToPrevPage: action.payload,
    }),
  },
});

export default slice.reducer;

// Actions
const {
  setAndShowToastMessage,
  closeToast,
  setAndShowPopup,
  closePopupBox,
  setProceedToNextPage,
  setProceedToPrevPage,
} = slice.actions;

// Toast
export const closeToastMessage = () => (dispatch: AppDispatch) => {
  dispatch(closeToast());
};

export const setAndShowErrorToast =
  (message: string) => (dispatch: AppDispatch) => {
    const toast = {
      open: true,
      color: 'error',
      message,
    };

    dispatch(setAndShowToastMessage(toast));
  };

export const setAndShowInfoToast =
  (message: string) => (dispatch: AppDispatch) => {
    const toast = {
      open: true,
      color: 'info',
      message,
    };

    dispatch(setAndShowToastMessage(toast));
  };

export const setAndShowSuccessToast =
  (message: string) => (dispatch: AppDispatch) => {
    const toast = {
      open: true,
      color: 'success',
      message,
    };

    dispatch(setAndShowToastMessage(toast));
  };

export const setAndShowWarningToast =
  (message: string) => (dispatch: AppDispatch) => {
    const toast = {
      open: true,
      color: 'warning',
      message,
    };

    dispatch(setAndShowToastMessage(toast));
  };

// Popup
export const setAndShowConfirmPopup =
  (
    title: string,
    body: any,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    onConfirm: Function
  ) =>
  (dispatch: AppDispatch) => {
    const popup = {
      id: uuidv4(),
      open: true,
      type: 'confirm',
      color: 'primary',
      title,
      body,
      onConfirm,
      buttonText: {
        close: cancelText,
        confirm: confirmText,
      },
    };
    dispatch(setAndShowPopup(popup));
  };
export const setAndShowDeletePopup =
  (deleteText: string, onConfirm: Function) => (dispatch: AppDispatch) => {
    const popup = {
      id: uuidv4(),
      open: true,
      type: 'delete',
      color: 'error',
      title: 'Are you sure?',
      body: `Are you sure you want to delete ${deleteText}`,
      onConfirm,
      buttonText: {
        close: 'Cancel',
        confirm: 'Delete',
      },
    };
    dispatch(setAndShowPopup(popup));
  };
export const closePopup = () => (dispatch: AppDispatch) => {
  dispatch(closePopupBox(false));
};

export const setIsProceedToNextPage =
  (isProceed: boolean) => (dispatch: AppDispatch) => {
    dispatch(setProceedToNextPage(isProceed));
  };

export const setIsProceedToPrevPage =
  (isProceed: boolean) => (dispatch: AppDispatch) => {
    dispatch(setProceedToPrevPage(isProceed));
  };
