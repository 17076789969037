import { MarketingPackageModel } from 'Api/Marketing/Types/Marketing';
import FormDetailsHeader from '../../Common/FormDetailsHeader';
import dayjs from 'dayjs';
import {
  addDividersBetweenElements,
  AgxBodyText,
  AgxButton,
  AgxColumn,
  AgxDivider,
  AgxHeader,
  AgxRow,
  AgxSparkleForm,
  Breakpoints,
  CampaignDetailModel,
  EnvelopeSubmissionStatus,
  EnvelopeSubmissionType,
  FormConfigType,
  StakeholderType,
  useWindowSize,
  Images,
  AgxSlideUpModal,
} from '@urbanx/agx-ui-components';
import { Signatory } from '../../../CampaignsList/components/SentForSigningPanel/Signatory';
import { useGetFileLink } from 'components/ui-components/File/fileApi';
import { openFileInNewTab } from 'utils/openFileInNewTab';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { removeMarketingPackageSubmission } from 'Api/Marketing/marketingApi';
import { useFormLoader } from 'hooks/useFormLoader';
import { useState } from 'react';

interface Props {
  onBack: () => void;
  campaign: CampaignDetailModel;
  marketingPackage: MarketingPackageModel;
  addressLineOne: string;
  addressLineTwo: string;
}

const MarketingPackageSigningPanel = (props: Props) => {
  const { onBack, campaign, marketingPackage, addressLineOne, addressLineTwo } =
    props;
  const { loadForm } = useFormLoader();
  const [, getAuthToken] = useAzureAuth();
  const screenSize = useWindowSize();
  const getFileLink = useGetFileLink();
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const {
    formDocumentSubmissionId: envelopeId,
    status: envelopeSubmissionStatus,
    type: envelopeSubmissionType,
    recipients = [],
  } = marketingPackage.envelopeDetails ?? {};

  const isMobile = screenSize === Breakpoints.Mobile;

  const declinedStatus = recipients?.find(
    recipient => recipient.status === EnvelopeSubmissionStatus.Declined
  );

  const bouncedStatus = recipients?.find(
    recipient => recipient.status === EnvelopeSubmissionStatus.Bounced
  );

  const downloadDocument = async () => {
    if (marketingPackage.document?.containerFilePath) {
      const fileLink = await getFileLink(
        marketingPackage.document?.containerFilePath
      );
      if (fileLink !== null) {
        openFileInNewTab(!isMobile, fileLink);
      }
    }
  };

  const onEditConfirm = async () => {
    const authToken = await getAuthToken();
    if (!authToken || !envelopeId) return null;
    await removeMarketingPackageSubmission(
      authToken,
      marketingPackage.id,
      envelopeId
    );
    await loadForm(
      campaign.campaignId,
      marketingPackage.id,
      FormConfigType.Marketing
    );
  };

  const confirmationDialogBody = (
    <AgxColumn veryLargeGap extraClasses={'confirmationModalStyle'}>
      <Images.AlertCircleOutline />
      <AgxHeader size={3}>
        Editing Marketing will void current DocuSign envelope
      </AgxHeader>
      <AgxBodyText medium>
        Current recipients will be notified, you'll be able to generate a new
        Marketing Package and send a new DocuSign envelope.
      </AgxBodyText>
      <AgxBodyText medium>Continue to edit?</AgxBodyText>
      <div className={'confirmationButtonContainerStyle'}>
        <AgxButton
          id="startsubmission"
          large
          primary
          text="Void and Edit"
          onClick={onEditConfirm}
        />
        <AgxButton
          id="cancel"
          hollow
          large
          text="No, go back"
          onClick={() => setShowConfirmationModel(false)}
        />
      </div>
    </AgxColumn>
  );

  const renderSignatories = () => {
    const signatoryComponents = recipients.map((signatory, i) => {
      if (
        signatory &&
        envelopeId &&
        ((envelopeSubmissionType === EnvelopeSubmissionType.DocuSign &&
          signatory.stakeholderType === StakeholderType.Vendor) ||
          envelopeSubmissionType === EnvelopeSubmissionType.Email)
      ) {
        return (
          <Signatory
            key={`signatory-${i}`}
            signatory={signatory}
            envelopeSubmissionType={envelopeSubmissionType}
            envelopeId={envelopeId}
            declinedStatus={!!(declinedStatus || bouncedStatus)}
          />
        );
      }

      return <></>;
    });

    return [
      <AgxDivider key="initialDivider" />,
      ...addDividersBetweenElements(signatoryComponents, false, false),
    ];
  };

  return (
    <>
      <AgxColumn centered extraClasses="listingInfoPanel">
        <FormDetailsHeader
          formName="Marketing"
          detail={`Last updated ${dayjs(marketingPackage.lastUpdated).format(
            'DD/MM/YY'
          )}`}
          currentTab={''}
          onBack={onBack}
        />
        <AgxColumn fill centered extraClasses="contractPanelTitle">
          <AgxHeader size={2} centered>
            {addressLineOne},
            <br />
            {addressLineTwo}
          </AgxHeader>
        </AgxColumn>

        <AgxRow fill justifyCenter>
          <AgxBodyText large>
            {marketingPackage.packageTemplateName}
          </AgxBodyText>
        </AgxRow>

        <AgxSparkleForm
          width={isMobile ? 150 : 200}
          height={isMobile ? 150 : 200}
        />

        <AgxRow fill justifyCenter largeGap>
          <AgxButton
            text="Download"
            hollow
            large={!isMobile}
            medium={isMobile}
            onClick={() => downloadDocument()}
          />
          <AgxButton
            text="Edit"
            hollow
            large={!isMobile}
            medium={isMobile}
            onClick={() => setShowConfirmationModel(true)}
          />
        </AgxRow>
        <AgxColumn fill centered extraClasses={'signatoriesContainer'}>
          {renderSignatories()}
        </AgxColumn>
      </AgxColumn>
      {showConfirmationModel && (
        <AgxSlideUpModal
          closeButton
          content={confirmationDialogBody}
          desktop={!isMobile}
          onClose={() => setShowConfirmationModel(false)}
        />
      )}
    </>
  );
};

export default MarketingPackageSigningPanel;
