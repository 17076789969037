import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FormType,
  Form,
  UploadedDocument,
  FormPage,
  PropertyAddress,
  PropertyDetails,
  PropertyImageUrls,
  PageStatus,
  DocumentTypes,
  AustralianState,
  FormConfigType,
} from '@urbanx/agx-ui-components';
import { PdfProcessedEvent } from './types';

interface FormState {
  selectedForm: {
    formType: FormType;
    state: AustralianState;
    formConfigType: FormConfigType;
  } | null;
  formId?: string;
  campaignId?: string;
  formName: string | null;
  formPages: FormPage[] | null;
  formValues: any;
  formErrors: string[];
  pageStatuses: PageStatus[];
  generatedFormDocuments: UploadedDocument[];
  initialGeneratedFormDocuments: UploadedDocument[];
  generatedDocumentTypes: DocumentTypes[];
  address: PropertyAddress | null;
  propertyDetails: PropertyDetails | null;
  propertyImageUrls: PropertyImageUrls | null;
}

const initialState = {
  selectedForm: null,
  formId: undefined,
  campaignId: undefined,
  formName: null,
  formPages: null,
  formValues: {},
  formErrors: [],
  pageStatuses: [],
  generatedFormDocuments: [],
  initialGeneratedFormDocuments: [],
  generatedDocumentTypes: [],
  address: null,
  propertyDetails: null,
  propertyImageUrls: null,
} satisfies FormState as FormState;

const slice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateFormValue: (state, action) => {
      const { id, value, parentPathIds = [] } = action.payload;

      const pathToElement = [...parentPathIds, id];

      return {
        ...state,
        formValues: applyValueToPath(pathToElement, state.formValues, value),
      };
    },
    updatePageStatuses: (
      state,
      action: PayloadAction<{ pageStatuses: PageStatus[] }>
    ) => {
      const { pageStatuses = [] } = action.payload;

      return {
        ...state,
        pageStatuses,
      };
    },
    updateFormErrors: (state, action: PayloadAction<string[]>) => {
      const errors = action.payload;

      return {
        ...state,
        formErrors: errors,
      };
    },
    deleteFormErrorIds: state => {
      return {
        ...state,
        formErrors: [],
      };
    },
    setSelectedForm: (
      state,
      action: PayloadAction<{
        formType: FormType;
        state: AustralianState;
        formConfigType: FormConfigType;
      }>
    ) => {
      const { formType, state: ausState, formConfigType } = action.payload;

      return {
        ...state,
        selectedForm: {
          formType,
          state: ausState,
          formConfigType,
        },
      };
    },
    loadFormConfig: (state, action: PayloadAction<Form>) => {
      const {
        formType,
        formConfigType,
        state: AusState,
        formId,
        campaignId,
        name,
        pages,
        existingData,
        pageStatuses,
        address,
        propertyDetails,
        propertyImageUrls,
        documents,
        generatedDocumentTypes,
      } = action.payload;

      if (!formId || !campaignId) {
        return state;
      }

      return {
        ...state,
        selectedForm: {
          formType,
          state: AusState,
          formConfigType,
        },
        formId,
        campaignId,
        formName: name,
        formPages: pages,
        formValues: existingData,
        pageStatuses,
        address: address,
        propertyDetails: propertyDetails,
        propertyImageUrls: propertyImageUrls,
        generatedFormDocuments: documents ?? [],
        initialGeneratedFormDocuments: documents ?? [],
        generatedDocumentTypes: generatedDocumentTypes ?? [],
      };
    },
    clearForm: state => {
      return {
        ...state,
        ...initialState,
      };
    },
    setGeneratedFormDocuments: (
      state,
      action: PayloadAction<PdfProcessedEvent>
    ) => {
      const { campaignId, formId, uploadedDocument } = action.payload;

      if (state.campaignId !== campaignId || state.formId !== formId) {
        return state;
      }

      return {
        ...state,
        generatedFormDocuments: [
          ...state.generatedFormDocuments.filter(
            doc => doc.documentType !== uploadedDocument.documentType
          ),
          uploadedDocument,
        ],
      };
    },
    clearGeneratedFormDocuments: state => {
      return {
        ...state,
        generatedFormDocuments: [...state.initialGeneratedFormDocuments],
      };
    },
  },
});

const getExistingValue = (pathIds: any, state: any): any => {
  const currentPathIds = [...pathIds];
  const currentPathId = currentPathIds.shift();

  if (currentPathIds.length === 0) {
    return state != null ? state[currentPathId] : null;
  }

  if (state && state[currentPathId] != null) {
    return getExistingValue(currentPathIds, state[currentPathId]);
  }

  return null;
};

const applyValueToPath = (
  pathIds: any,
  state: any,
  value: string
): Record<string, any> => {
  const remainingPathIds = [...pathIds];
  const currentPathId = remainingPathIds.shift();

  if (state == null) {
    state = {};
  }

  if (remainingPathIds.length === 0) {
    return {
      ...state,
      [currentPathId]: value,
    };
  }

  return {
    ...state,
    [currentPathId]: applyValueToPath(
      remainingPathIds,
      state[currentPathId],
      value
    ),
  };
};

export default slice.reducer;

const {
  updateFormValue,
  updatePageStatuses,
  updateFormErrors,
  deleteFormErrorIds,
  setSelectedForm,
  loadFormConfig,
  clearForm,
  setGeneratedFormDocuments,
  clearGeneratedFormDocuments,
} = slice.actions;

export {
  updateFormValue,
  updatePageStatuses,
  updateFormErrors,
  deleteFormErrorIds,
  setSelectedForm,
  loadFormConfig,
  clearForm,
  setGeneratedFormDocuments,
  getExistingValue,
  clearGeneratedFormDocuments,
};
