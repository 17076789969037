import React, { useEffect, useState } from 'react';
import {
  AgxSelect,
  AgxTextInput,
  AgxCurrency,
  AgxCaption,
  AgxLabel,
} from '@urbanx/agx-ui-components';
import { parseFloatReturnEmptyIfZero } from 'utils/parseFloatReturnEmptyIfZero';
import './FlatCommission.scss';

const FlatCommission = ({
  id,
  onValueChanged,
  defaultValue,
  validate,
  required,
  small = false,
}) => {
  const options = [
    {
      value: 'Percentage',
      label: 'Percentage',
    },
    {
      value: 'FlatFee',
      label: 'Flat Fee',
    },
  ];

  const [commissionType, setCommissionType] = useState(
    defaultValue?.flatCommissionType ?? 'Percentage'
  );
  const [commissionRate, setCommissionRate] = useState(defaultValue?.amount);

  useEffect(() => {
    onValueChanged({
      id,
      value: {
        flatCommissionType: commissionType,
        amount: !commissionRate ? 0.0 : commissionRate,
      },
    });
  }, [commissionRate, commissionType]);

  return (
    <div className="agx-flatCommission">
      <AgxLabel medium>Commission Rate</AgxLabel>
      <div className="agx-flatCommission-inputs">
        <AgxSelect
          id={id}
          options={options}
          onValueChanged={({ value }) => {
            if(commissionType !== value)
              setCommissionRate(null);

            setCommissionType(value);
          }}
          defaultValue={
            options.find(option => option.value === commissionType) ??
            options[0]
          }
          required
          noHeader
        />
        {commissionType === 'FlatFee' ? (
          <AgxCurrency
            id={`${id}-input`}
            onInputValueChange={({ value }) => setCommissionRate(value)}
            noHeader
            currency={commissionType === 'FlatFee'}
            defaultValue={parseFloatReturnEmptyIfZero(commissionRate)}
            displayErrors={true}
            validate={validate}
            required={required}
            error={
              validate &&
              required &&
              !parseFloatReturnEmptyIfZero(commissionRate)
                ? 'Enter commission rate'
                : undefined
            }
          />
        ) : (
          <AgxTextInput
            id={`${id}-input`}
            onInputValueChange={({ value }) => setCommissionRate(value)}
            noHeader
            percent={commissionType === 'Percentage'}
            defaultValue={parseFloatReturnEmptyIfZero(commissionRate)}
            validate={validate}
            required={required}
            error={
              validate &&
              required &&
              !parseFloatReturnEmptyIfZero(commissionRate)
                ? 'Enter commission rate'
                : ''
            }
          />
        )}
      </div>
      <AgxCaption>Including GST</AgxCaption>
    </div>
  );
};

export default FlatCommission;
