import React, { useMemo } from 'react';
import './Leaderboard.scss';
import LeaderboardRowLoader from './LeaderboardRow/LeaderboardRowLoader';
import LeaderboardRow from './LeaderboardRow/LeaderboardRow';
import {
  AgxBodyText,
  AgxColumn,
  AgxDivider,
  AgxHeader,
  AgxRow,
} from '@urbanx/agx-ui-components';
import { formatNumber } from 'utils/formatNumber';
import moment from 'moment';
import { EntityTypes } from '../reportingDashboardReducer';
import NoDataImg from 'assets/images/Curiosity.svg?react';
import {
  LeaderBoardStatsModel,
  TimePeriodType,
} from '../../../types/Reporting';

const currentTimePeriod = (timePeriod: string): string => {
  if (!timePeriod) return '';

  switch (timePeriod) {
    case TimePeriodType.Month:
      return moment().format('MMMM YYYY');
    case TimePeriodType.Quarter:
      const yearQuarter = [
        'Jan to Mar',
        'Apr to Jun',
        'Jul to Sep',
        'Oct to Dec',
      ];
      const currentQuarter = moment().quarter();
      return yearQuarter[currentQuarter - 1] + ' ' + moment().format('YYYY');
    case TimePeriodType.Annual:
      return moment().format('YYYY');
    default:
      return '';
  }
};

interface LeaderboardProps {
  loading: boolean;
  leaderboardStats?: LeaderBoardStatsModel | null;
  timePeriod: string;
  entityType: string;
}

const Leaderboard = ({
  loading,
  leaderboardStats,
  timePeriod,
  entityType,
}: LeaderboardProps) => {
  const panelHeaderText = useMemo(() => {
    const entityTypeText =
      entityType === EntityTypes[0].value ? 'Performers' : 'Partners';

    return `Top ${entityTypeText} - ${currentTimePeriod(timePeriod)}`;
  }, [timePeriod, entityType]);

  const noData = !loading && !leaderboardStats;

  const leaderboardTitle =
    entityType === EntityTypes[1].value ? 'Agency' : 'Agent';

  return (
    <AgxColumn extraClasses="reportLeaderboard">
      <AgxHeader size={5}>
        {loading && 'Loading leaderboard data'}
        {!loading && panelHeaderText}
      </AgxHeader>
      <div className="leaderboardGrid">
        <AgxRow fill extraClasses="card-header" spaceBetween>
          <AgxBodyText small>{leaderboardTitle}</AgxBodyText>
          <AgxBodyText small>GCI</AgxBodyText>
        </AgxRow>
        {noData && (
          <>
            <AgxDivider />
            <AgxColumn fill centered extraLargeGap extraClasses="noDataDiv">
              <NoDataImg />
              <AgxColumn>
                <AgxHeader size={3} centered>
                  No sales have been recorded yet
                </AgxHeader>
                <AgxBodyText medium centered>
                  Check back in a bit!
                </AgxBodyText>
              </AgxColumn>
            </AgxColumn>
          </>
        )}
        {loading &&
          [...Array(10)].map((i, idx) => <LeaderboardRowLoader key={idx} />)}
        {!loading &&
          leaderboardStats?.leaderBoardModels.map(
            (leaderboardPosition, idx) => {
              return (
                <LeaderboardRow
                  key={`row-${idx}`}
                  leaderboardPosition={leaderboardPosition}
                  addRingToImage={entityType === EntityTypes[0].value}
                />
              );
            }
          )}
      </div>
      {!loading &&
        leaderboardStats?.personalPosition !== undefined &&
        leaderboardStats?.personalPosition !== null &&
        leaderboardStats.personalPosition.rank >= 10 &&
        leaderboardStats.personalPosition.amount > 0 && (
          <AgxRow
            fill
            centered
            key={leaderboardStats.personalPosition.rank}
            mediumGap
            extraClasses="leaderboard-row-hidden"
          >
            <AgxBodyText small extraClasses="leaderboard-row-hidden-text">
              {leaderboardStats.personalPosition.rank}
            </AgxBodyText>

            <AgxRow fill centered extraClasses="image-agent">
              <img
                src={
                  leaderboardStats.personalPosition.photoUri ||
                  'defaultImagePath'
                }
                alt="Agent"
                style={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '50%',
                }}
              />
              <AgxBodyText
                small
                extraClasses="leaderboard-row-hidden-text name"
              >
                {leaderboardStats.personalPosition.name}
              </AgxBodyText>
            </AgxRow>

            <AgxBodyText small extraClasses="leaderboard-row-hidden-text sales">
              $
              {formatNumber(
                leaderboardStats.personalPosition.amount.toString()
              )}
            </AgxBodyText>
          </AgxRow>
        )}
    </AgxColumn>
  );
};

export default Leaderboard;
