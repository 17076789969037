import {
  AgxColumn,
  AgxLabel,
  AgxRow,
  AgxPriceRange,
} from '@urbanx/agx-ui-components';

interface PriceOpinionProps {
  id: string;
  onValueChanged: (value: any, saveChanges?: boolean) => Promise<void>;
  label?: string;
  defaultValue?: any;
  maxPriceRangePercent?: number;
}

const PriceOpinion = (props: PriceOpinionProps) => {
  const {
    id,
    label,
    onValueChanged,
    defaultValue = null,
    maxPriceRangePercent,
  } = props;

  return (
    <AgxColumn fill veryLargeGap>
      <AgxRow fill spaceBetween>
        <AgxLabel large>{label}</AgxLabel>
      </AgxRow>
      <AgxPriceRange
        id={id}
        label="Property and improvements"
        onValueChanged={change => {
          onValueChanged({
            id,
            value: {
              ...defaultValue,
              propertyAndImprovements: {
                ...change,
              },
            },
          });
        }}
        defaultValue={defaultValue?.propertyAndImprovements}
        defaultUpperRangeSelected={true}
        maxPriceRangePercent={maxPriceRangePercent}
      />
    </AgxColumn>
  );
};

export default PriceOpinion;
