import './CircularSelector.scss';
import CheckmarkIcon from './assets/icons/checkmark.svg?react';
import clsx from 'clsx';

export const CircularSelector = ({ selected, onClicked }) => {
  const circleClass = clsx(
    'agxCircularSelector-circle',
    selected && 'selected'
  );

  const onSelectorClicked = e => {
    e.preventDefault();
    onClicked();
  };

  return (
    <div className="agxCircularSelector" onClick={onSelectorClicked}>
      <div className={circleClass}>
        {selected && <CheckmarkIcon className="agx-circularSelector-icon" />}
      </div>
    </div>
  );
};
