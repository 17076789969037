import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ConnectionState } from '../utils/connectionState';
import { AppDispatch } from './store';

interface NotificationsState {
  connectionState: ConnectionState;
  connectionUpdatedAt: string;
}

export type ConnectionName = 'marketing' | 'campaigns';
export type NotificationState = Record<ConnectionName, NotificationsState>;

const initialState: NotificationState = {
  marketing: { connectionState: ConnectionState.NotConnected, connectionUpdatedAt: '' },
  campaigns: { connectionState: ConnectionState.NotConnected, connectionUpdatedAt: '' },
};

const slice = createSlice({
  name: 'notifications',
  initialState: initialState,
  reducers: {
    updateConnectionState: (state, action: PayloadAction<{connectionState: ConnectionState, connectionName: ConnectionName}>) => {
      const connectionState = action.payload.connectionState;
      const connectionName = action.payload.connectionName;
      const connectionUpdatedAt = new Date();

      return {
        ...state, [connectionName]: {connectionState, connectionUpdatedAt}
      }
    },
  },
});

export default slice.reducer;

// Actions
const { updateConnectionState } = slice.actions;

export const updateConnection =
  (connectionState: ConnectionState, connectionName: ConnectionName) => async (dispatch: AppDispatch) => {
    dispatch(updateConnectionState({connectionState, connectionName}));
  };
