import React, { useState, useEffect } from 'react';
import {
  AgxColumn,
  AgxRow,
  AgxSelect,
  AgxLabel,
  AgxCurrency,
  AgxPercent,
  AgxRadio,
  AgxCaption,
  AgxColours,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  DepositOptions,
  DepositRadioOptionType,
  DepositValue,
  DepositValueType,
  ErrorState,
} from './types';
import './Deposit.scss';

interface DepositProps {
  id: string;
  label: string;
  onValueChanged: (change: { id: string; value: DepositValue }) => void;
  defaultValue: DepositValue;
  required?: boolean;
  validate?: boolean;
  balanceDepositValidation?: boolean;
  error?: ErrorState;
  decimalPoints?: number;
  showDepositDropdownOption?: boolean;
}

export const MaxDepositPercentage = 0.1;
export const ErrorMessage = `Deposit can't exceed ${MaxDepositPercentage * 100}% of the sale price`;

export const CalculateAmount = (
  salePrice: number,
  deposit: { depositValueType: string; amount?: string; percent?: string }
) => {
  const amount = deposit?.amount ? Number(deposit.amount) : 0;
  const percent = deposit?.percent ? Number(deposit.percent) : 0;

  return deposit?.depositValueType === DepositValueType.Percentage
    ? salePrice * (percent / 100)
    : amount;
};

export const DefaultErrorState = {
  depositError: false,
  message: '',
};

const Deposit: React.FC<DepositProps> = ({
  id,
  label,
  onValueChanged,
  defaultValue,
  required = false,
  validate = false,
  balanceDepositValidation = false,
  error = DefaultErrorState,
  decimalPoints = 1,
  showDepositDropdownOption = true,
}) => {
  const { SalePrice } = useAppSelector(state => state.form.formValues);

  const [depositValue, setDepositValue] = useState<DepositValue>({
    depositType: defaultValue?.depositType,
    depositValueType:
      defaultValue?.depositValueType ?? DepositValueType.Percentage,
    amount: defaultValue?.amount,
    percent: showDepositDropdownOption
      ? defaultValue?.percent
      : (defaultValue?.percent ?? DepositRadioOptionType.TenPercentage),
  });

  const [errorState, setErrorState] = useState<ErrorState>(DefaultErrorState);

  const validateDepositTotal = () => {
    const amount = CalculateAmount(SalePrice, depositValue);

    const isInitialDepositInvalid = amount > SalePrice * MaxDepositPercentage;
    setErrorState({
      depositError: isInitialDepositInvalid,
      message: isInitialDepositInvalid ? ErrorMessage : undefined,
    });
  };

  useEffect(() => {
    depositValue.depositType !== undefined
      ? setErrorState(error)
      : validateDepositTotal();

    onValueChanged({
      id,
      value: { ...depositValue },
    });
  }, [depositValue, SalePrice]);

  useEffect(() => {
    setErrorState(error);
  }, [error]);

  const handleDepositValueTypeChange = (value: string) => {
    setDepositValue({
      ...depositValue,
      depositValueType: value as DepositValueType,
      amount:
        value === DepositValueType.Dollar ? depositValue?.amount : undefined,
      percent:
        value === DepositValueType.Percentage
          ? depositValue?.percent
          : undefined,
    });
  };

  const getCustomAmountSubContent = () => {
    return [
      <AgxRow centered mediumGap key="customAmountInput">
        <AgxCurrency
          id={`dollar-${id}`}
          defaultValue={depositValue?.amount}
          onInputValueChange={({ value }) =>
            setDepositValue({
              ...depositValue,
              amount: value ? value : undefined,
              percent: undefined,
            })
          }
          stretch
          noOptionalLabel
          displayErrors={
            errorState.depositError || depositValue?.amount === undefined
          }
          required={required}
          validate={validate || errorState.depositError}
          error={
            errorState.depositError
              ? errorState.message
              : 'Enter deposit amount'
          }
        />
      </AgxRow>,
    ];
  };

  const defaultRadioOption = () =>
    depositValue.depositValueType === DepositValueType.Percentage &&
    !depositValue.percent &&
    !depositValue.amount;

  return (
    <AgxColumn smallGap extraClasses="deposit">
      <AgxRow fill spaceBetween>
        <AgxLabel medium>{label}</AgxLabel>
        {!required && (
          <AgxCaption colour={AgxColours.NEUTRAL_GREY_600}>optional</AgxCaption>
        )}
      </AgxRow>
      {showDepositDropdownOption ? (
        <AgxRow mediumGap>
          <AgxSelect
            options={DepositOptions}
            id={`select-${id}`}
            defaultValue={DepositOptions.find(
              option => option.value === depositValue?.depositValueType
            )}
            onValueChanged={({ value }) => handleDepositValueTypeChange(value)}
            hideOptionalLabel
            required
            small
          />
          {depositValue?.depositValueType === DepositValueType.Percentage && (
            <AgxPercent
              id={`percent-${id}`}
              defaultValue={depositValue?.percent}
              onInputValueChange={({ value }) =>
                setDepositValue({
                  ...depositValue,
                  percent: value ? value : undefined,
                  amount: undefined,
                })
              }
              decimalPoints={decimalPoints}
              stretch
              noOptionalLabel
              displayErrors={
                errorState.depositError || depositValue?.percent === undefined
              }
              required={required || balanceDepositValidation}
              validate={validate || errorState.depositError}
              error={errorState.message}
            />
          )}
          {depositValue?.depositValueType === DepositValueType.Dollar && (
            <AgxCurrency
              id={`dollar-${id}`}
              defaultValue={depositValue?.amount}
              onInputValueChange={({ value }) =>
                setDepositValue({
                  ...depositValue,
                  amount: value ? value : undefined,
                  percent: undefined,
                })
              }
              stretch
              noOptionalLabel
              displayErrors={
                errorState.depositError || depositValue?.amount === undefined
              }
              required={required || balanceDepositValidation}
              validate={validate || errorState.depositError}
              error={errorState.message}
            />
          )}
        </AgxRow>
      ) : (
        <div className="agxRadio-group">
          <AgxRadio
            id={`ten-percent-${id}`}
            label={`${DepositRadioOptionType.TenPercentage}%`}
            onCheckChanged={() =>
              setDepositValue({
                ...depositValue,
                depositValueType: DepositValueType.Percentage,
                percent: DepositRadioOptionType.TenPercentage,
                amount: undefined,
              })
            }
            checked={
              (depositValue.depositValueType === DepositValueType.Percentage &&
                depositValue.percent ===
                  DepositRadioOptionType.TenPercentage) ||
              defaultRadioOption()
            }
          />
          <AgxRadio
            id={`five-percent-${id}`}
            label={`${DepositRadioOptionType.FivePercentage}%`}
            onCheckChanged={() =>
              setDepositValue({
                ...depositValue,
                depositValueType: DepositValueType.Percentage,
                percent: DepositRadioOptionType.FivePercentage,
                amount: undefined,
              })
            }
            checked={
              depositValue.depositValueType === DepositValueType.Percentage &&
              depositValue.percent === DepositRadioOptionType.FivePercentage
            }
          />
          <AgxRadio
            id={`custom-dollar-${id}`}
            label="$__"
            onCheckChanged={() =>
              setDepositValue({
                ...depositValue,
                depositValueType: DepositValueType.Dollar,
              })
            }
            checked={depositValue.depositValueType === DepositValueType.Dollar}
            subContent={
              depositValue.depositValueType === DepositValueType.Dollar
                ? getCustomAmountSubContent()
                : undefined
            }
          />
        </div>
      )}
    </AgxColumn>
  );
};

export default Deposit;
