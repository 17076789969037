import { useCallback, useState } from 'react';
import {
  AgxCaption,
  AgxColours,
  AgxColumn,
  AgxRow,
  AgxButton,
  Images,
  CampaignStage,
} from '@urbanx/agx-ui-components';
import { CampaignStageAction } from 'Api/Campaigns/Types/campaignTypes';
import { usePerformCampaignAction } from 'features/campaigns/campaignsReducer';
import { Tabs } from 'types/Tabs';
import { useSelectedCampaign } from 'hooks/useSelectedCampaign';
import './FormDetailsHeader.scss';
import { getCampaignStageEnumKeyByValue } from 'helpers/enumHelper';

const FormDetailsHeader = ({
  id,
  formName,
  detail,
  currentTab,
  onBack,
}: {
  id?: string;
  formName: string;
  detail: any;
  currentTab: string;
  onBack?: () => void;
}) => {
  const selectedCampaign = useSelectedCampaign();

  const [disableArchiveButton, setDisableArchiveButton] = useState(false);
  const performCampaignAction = usePerformCampaignAction();

  const performAction = async (action: CampaignStageAction) => {
    await performCampaignAction({
      campaignId,
      campaignAction: action,
    });

    setDisableArchiveButton(false);
  };

  const onArchiveCampaign = useCallback(() => {
    if (disableArchiveButton) return;
    setDisableArchiveButton(true);
    performAction(CampaignStageAction.Archive);
  }, [setDisableArchiveButton, disableArchiveButton, performAction]);

  const onRestoreCampaign = () => {
    performAction(CampaignStageAction.Restore);
  };

  if (!selectedCampaign) return;

  const { campaignId, stage: campaignStage } = selectedCampaign ?? {};

  const isCampaignStageValid =
    campaignStage ===
    getCampaignStageEnumKeyByValue(CampaignStage.AgreementDraft);

  const shouldRenderRestore =
    isCampaignStageValid && currentTab === Tabs.Archive;
  const shouldRenderArchive =
    isCampaignStageValid &&
    currentTab !== Tabs.Archive &&
    currentTab !== Tabs.Listings;

  const title = (
    <AgxCaption
      dataTestId={`agx-${id}-form-name`}
      colour={AgxColours.NEUTRAL_GREY_600}
    >
      {formName}
    </AgxCaption>
  );

  const backButton = !!onBack && (
    <AgxRow
      centered
      justifyCenter
      extraClasses="formDetailsBack"
      onClick={onBack}
    >
      <Images.ArrowBackOutline />
    </AgxRow>
  );

  const detailSection = (
    <AgxCaption
      colour={AgxColours.NEUTRAL_GREY_600}
      dataTestId={`agx-${id}-date`}
    >
      {detail}
    </AgxCaption>
  );

  return (
    <>
      <AgxRow spaceBetween fill extraClasses="formDetailsHeaderMobile" centered>
        <div>
          {shouldRenderRestore && (
            <div className="restore" onClick={onRestoreCampaign}>
              <Images.RestoreOutline />
            </div>
          )}
          {shouldRenderArchive && !disableArchiveButton && (
            <div className="archive" onClick={onArchiveCampaign}>
              <Images.ArchiveOutline />
            </div>
          )}
        </div>
        {backButton}
        <AgxColumn
          centerJustified
          centered
          extraClasses="formDetailsTitleLastUpdated"
        >
          {title}
          {detailSection}
        </AgxColumn>
      </AgxRow>
      <AgxRow
        spaceBetween
        fill
        extraClasses="formDetailsHeaderDesktop"
        centered
      >
        <AgxRow
          smallGap
          centered
          extraClasses="formDetailsTitleInfo"
          onClick={onBack}
        >
          {backButton}
          {title}
        </AgxRow>
        {detailSection}
        {shouldRenderRestore && (
          <AgxButton
            naked
            text="Restore"
            extraClasses="formDetailsArchiveButton"
            onClick={onRestoreCampaign}
          />
        )}
        {shouldRenderArchive && (
          <AgxButton
            naked
            text="Archive"
            disabled={disableArchiveButton}
            extraClasses="formDetailsArchiveButton"
            onClick={onArchiveCampaign}
          />
        )}
      </AgxRow>
    </>
  );
};

export default FormDetailsHeader;
