import { useCallback } from 'react';
import { useAzureAuth } from './useAzureAuth';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import { saveFormCampaign } from 'Api/Campaigns/campaignsApi';
import { markCampaignsAsStale } from '../features/campaigns/campaignsReducer';
import { setAndShowErrorToast } from '../store/config';
import { FormConfigType } from '@urbanx/agx-ui-components';
import { saveFormMarketing } from 'Api/Marketing/marketingApi';

export const useFormSaver = () => {
  const [, getAuthToken] = useAzureAuth();
  const dispatch = useAppDispatch();
  const {
    campaignId,
    formId,
    selectedForm,
    formValues: formData,
    pageStatuses,
  } = useAppSelector(state => state.form);
  const { formType, state } = selectedForm || {};

  const saveFormData = useCallback(
    async (formConfigType: FormConfigType) => {
      let errorMessage: string = '';
      switch (formConfigType) {
        case FormConfigType.Campaign: {
          errorMessage = 'Failed to save form campaign';
          break;
        }
        case FormConfigType.Marketing: {
          errorMessage = 'Failed to save form marketing package';
          break;
        }
        default: {
          errorMessage = 'Failed to save form';
        }
      }
      if (!getAuthToken) return;

      if (!campaignId || !formId || !formType || !state) {
        dispatch(setAndShowErrorToast(errorMessage));
        return;
      }

      try {
        const authToken = await getAuthToken();

        if (!authToken) {
          dispatch(setAndShowErrorToast(errorMessage));
          return;
        }

        switch (formConfigType) {
          case FormConfigType.Campaign: {
            const formSaved = await saveFormCampaign(
              authToken,
              campaignId,
              formId,
              formType,
              state,
              formData,
              pageStatuses
            );

            if (formSaved) {
              dispatch(markCampaignsAsStale());
            }
            break;
          }
          case FormConfigType.Marketing: {
            await saveFormMarketing(
              authToken,
              campaignId,
              formId,
              formType,
              state,
              formData,
              pageStatuses
            );
            break;
          }
        }
      } catch (err: any) {
        console.error(err);
        dispatch(setAndShowErrorToast(err.message));
      }
    },
    [campaignId, formId, formType, state, formData, pageStatuses]
  );

  return saveFormData;
};
