import { useState } from 'react';
import {
  AgxHeader,
  AgxButton,
  AgxRow,
  AgxColumn,
  FormType,
  useWindowSize,
  Breakpoints,
  AgxAgentAlert,
  FormConfigType,
} from '@urbanx/agx-ui-components';
import moment from 'moment';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { beginCampaignForm } from 'Api/Campaigns/campaignsApi';
import { useFormLoader } from 'hooks/useFormLoader';
import { cleanTwoLineAddress } from 'utils/formatAddress';
import SegmentedPicker from '../../../../SegmentedPicker/SegmentedPicker';
import { CampaignInfoProps } from 'Api/Campaigns/Types/campaignTypes';
import { setAndShowErrorToast } from 'store/config';
import FormDetailsHeader from '../Common/FormDetailsHeader';
import ListingDetails from './Common/ListingDetails';
import ContractDetails from './Common/ContractDetails';
import './ListingInfoPanel.scss';
import { MarketingPackageModel } from 'Api/Marketing/Types/Marketing';
import MarketingPackageSigningPanel from './MarketingPackage/MarketingPackageSigningPanel';
import { useGetFileLink } from 'components/ui-components/File/fileApi';
import { openFileInNewTab } from 'utils/openFileInNewTab';

const TabType = {
  ListingDetails: 'ListingDetails',
  Contracts: 'Contracts',
};

const ListingInfoPanel = ({
  campaign,
  currentTab,
  mobile = false,
}: CampaignInfoProps) => {
  const screenSize = useWindowSize();
  const [selectedTab, setSelectedTab] = useState(TabType.Contracts);
  const [disableButtons, setDisableButtons] = useState(false);
  const [, getAuthToken] = useAzureAuth();
  const { loadForm } = useFormLoader();
  const [selectedMarketingPackage, setSelectedMarketingPackage] = useState<
    MarketingPackageModel | undefined
  >();
  const availableForms = useAppSelector(state => state.forms.availableForms);
  const getFileLink = useGetFileLink();

  const isArchived = !!campaign.completionState?.completionType;

  const { address, campaignId, blankContractIsLive, lastUpdated } = campaign;

  const [addressLineOne, addressLineTwo] = cleanTwoLineAddress(address);

  const segments = [
    {
      title: 'Contracts',
      isSelected: selectedTab === TabType.Contracts,
      onSelected: () => setSelectedTab(TabType.Contracts),
    },
    {
      title: 'Listing Details',
      isSelected: selectedTab === TabType.ListingDetails,
      onSelected: () => setSelectedTab(TabType.ListingDetails),
    },
  ];

  const lastUpdatedOn = `Last updated ${moment(lastUpdated).format(
    'DD/MM/YY'
  )}`;

  const onDownloadBlankContract = async () => {
    if (campaign.blankSalesContract) {
      const fileLink = await getFileLink(
        campaign.blankSalesContract.containerFilePath
      );
      if (fileLink != null) {
        openFileInNewTab(screenSize === Breakpoints.Desktop, fileLink);
      }
    }
  };

  const beginContract = async () => {
    if (!getAuthToken) return;

    const contractRequestForm = availableForms.find(
      (form: any) => form.type === FormType.ContractRequest
    );

    if (!contractRequestForm) return;

    try {
      setDisableButtons(true);

      const authToken = await getAuthToken();

      if (!authToken) {
        setDisableButtons(false);
        return;
      }

      const newFormId = await beginCampaignForm(
        authToken,
        campaignId,
        contractRequestForm.type,
        contractRequestForm.state
      );

      if (!newFormId) {
        setAndShowErrorToast('Failed to start Contract submission');
        setDisableButtons(false);
        return;
      }

      await loadForm(campaignId, newFormId, FormConfigType.Campaign);
    } catch (err) {
      console.error(err);
    }

    setDisableButtons(false);
  };

  const beginSubmission = async () => {
    if (!getAuthToken) return;

    const contractSubmissionForm = availableForms.find(
      (form: any) => form.type === FormType.ContractSubmission
    );

    if (!contractSubmissionForm) return;

    try {
      setDisableButtons(true);

      const authToken = await getAuthToken();

      if (!authToken) {
        setDisableButtons(false);
        return;
      }

      const newFormId = await beginCampaignForm(
        authToken,
        campaignId,
        contractSubmissionForm.type,
        contractSubmissionForm.state
      );

      if (!newFormId) {
        setAndShowErrorToast('Failed to start Contract submission');
        setDisableButtons(false);
        return;
      }

      await loadForm(campaignId, newFormId, FormConfigType.Campaign);
    } catch (err) {
      console.error(err);
    }

    setDisableButtons(false);
  };

  if (
    selectedMarketingPackage &&
    selectedMarketingPackage.stage.value === 'Vendor Signing'
  ) {
    return (
      <MarketingPackageSigningPanel
        onBack={() => setSelectedMarketingPackage(undefined)}
        marketingPackage={selectedMarketingPackage}
        addressLineOne={addressLineOne}
        addressLineTwo={addressLineTwo}
        campaign={campaign}
      />
    );
  }

  return (
    <>
      <AgxColumn centered extraClasses="listingInfoPanel">
        <FormDetailsHeader
          id="listing-info-panel-header"
          formName="Listing"
          detail={lastUpdatedOn}
          currentTab={currentTab}
          onBack={undefined}
        />
        <AgxColumn fill centered extraClasses="contractPanelTitle">
          <AgxHeader
            size={2}
            centered
            dateTestId="agx-listing-info-panel-address"
          >
            {addressLineOne},
            <br />
            {addressLineTwo}
          </AgxHeader>
        </AgxColumn>

        <AgxRow fill justifyCenter>
          <AgxRow extraClasses={'listingSegmentedPickerContainer'}>
            <SegmentedPicker segments={segments} id={'agx-listing-segments'} />
          </AgxRow>
        </AgxRow>

        {selectedTab === TabType.Contracts && (
          <AgxRow fill justifyCenter>
            <AgxColumn extraClasses={'listingSegmentedPickerContainer'}>
              <ContractDetails
                campaign={campaign}
                property={address}
                contractDetails={campaign.contractDetails}
                completionState={campaign.completionState}
                propertyImageUrls={{
                  thumbnail: campaign.propertyImageUrls.thumbnail,
                }}
              />

              {campaign.blankContractIsLive && campaign.blankSalesContract && (
                <AgxColumn extraLargePadding>
                  <AgxButton
                    dataTestId="agx-submit-contract"
                    hollow
                    large={!mobile}
                    medium={mobile}
                    wide
                    text="Download Blank Contract"
                    onClick={onDownloadBlankContract}
                  />
                </AgxColumn>
              )}
            </AgxColumn>
          </AgxRow>
        )}

        {selectedTab === TabType.ListingDetails && (
          <ListingDetails
            campaign={campaign}
            mobile={mobile}
            setSelectedMarketingPackage={setSelectedMarketingPackage}
          />
        )}
      </AgxColumn>

      {!campaign.blankContractIsLive && campaign.contractDetails?.length > 0 ? (
        <AgxAgentAlert
          extraClasses="offlineContractAlert"
          heading="New Contract generation is disabled."
          body={
            screenSize === Breakpoints.Mobile
              ? 'Contact Support'
              : 'Contact your administrator'
          }
        />
      ) : null}
      <div className="campaignModalButtons">
        <AgxButton
          dataTestId="agx-submit-contract"
          hollow
          large
          wide={mobile}
          text="Submit Contract"
          shrinkOnLargerDevices
          onClick={beginSubmission}
          disabled={disableButtons || isArchived}
        />
        <AgxButton
          dataTestId="agx-begin-contract"
          primary
          large
          wide={mobile}
          text="New Contract"
          shrinkOnLargerDevices
          onClick={beginContract}
          disabled={!blankContractIsLive || disableButtons || isArchived}
        />
      </div>
    </>
  );
};

export default ListingInfoPanel;
