import React, { useState } from 'react';
import { useEffect } from 'react';
import { HubConnection } from '@microsoft/signalr';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { ConnectionState } from 'utils/connectionState';
import getConnection, {
  receiveNotifications,
} from '../Notifications/notifications';
import { updateCampaign } from 'features/campaigns/campaignsReducer';
import { setGeneratedFormDocuments } from 'features/form/formReducer';
import { ConnectionName, updateConnection } from 'store/notificationsReducer';
import { PdfProcessedEvent } from 'features/form/types';
import { CampaignUpdatedEvent } from 'Api/Campaigns/Types/types';
import { MarketingPackageUpdatedEvent } from 'Api/Marketing/Types/Marketing';
import { FormConfigType } from '@urbanx/agx-ui-components';

interface SetupNotificationsLayoutProps {
  children: React.ReactNode;
}

export const SetupNotificationsLayout = ({
  children,
}: SetupNotificationsLayoutProps) => {
  const [hubConnection, setHubConnection] = useState<
    Map<ConnectionName, HubConnection>
  >(new Map());
  const [lockResolver, setLockResolver] = useState<
    ((value?: unknown) => void) | null
  >(null);
  const dispatch = useAppDispatch();

  const [userAccount, getAuthToken] = useAzureAuth();
  const notificationState = useAppSelector(state => state.notifications);

  const attachEventListenersForCampaigns = () => {
    const connectionName: ConnectionName = 'campaigns';
    dispatch(updateConnection(ConnectionState.Connecting, connectionName));

    const hubCon = getConnection(
      `${import.meta.env.VITE_REACT_APP_AGENTX_BASE_API}/${connectionName}`,
      getAuthToken,
      () => {
        dispatch(updateConnection(ConnectionState.Connected, connectionName));
      }
    );

    hubCon.onclose(() => {
      dispatch(updateConnection(ConnectionState.Disconnected, connectionName));
    });

    hubCon.onreconnecting(() => {
      dispatch(updateConnection(ConnectionState.Reconnecting, connectionName));
    });

    hubCon.onreconnected(() => {
      dispatch(updateConnection(ConnectionState.Connected, connectionName));
    });

    receiveNotifications(
      hubCon,
      'PdfProcessedEvent',
      (data: PdfProcessedEvent) => {
        dispatch(setGeneratedFormDocuments(data));
      }
    );

    receiveNotifications(
      hubCon,
      'CampaignUpdatedEvent',
      async (data: CampaignUpdatedEvent) => {
        const authToken = await getAuthToken();
        if (!!authToken) {
          dispatch(updateCampaign(authToken, data.campaignId));
        }
      }
    );

    dispatch(updateConnection(ConnectionState.Connected, connectionName));

    setHubConnection(previousConnections => {
      const newConnection = new Map(previousConnections);
      newConnection.set('campaigns', hubCon);
      return newConnection;
    });
  };

  const attachEventListenersForMarketing = () => {
    const connectionName: ConnectionName = 'marketing';
    dispatch(updateConnection(ConnectionState.Connecting, connectionName));

    const hubCon = getConnection(
      `${import.meta.env.VITE_REACT_APP_AGENTX_BASE_API}/${connectionName}`,
      getAuthToken,
      () => {
        dispatch(updateConnection(ConnectionState.Connected, connectionName));
      }
    );

    hubCon.onclose(() => {
      dispatch(updateConnection(ConnectionState.Disconnected, connectionName));
    });

    hubCon.onreconnecting(() => {
      dispatch(updateConnection(ConnectionState.Reconnecting, connectionName));
    });

    hubCon.onreconnected(() => {
      dispatch(updateConnection(ConnectionState.Connected, connectionName));
    });

    receiveNotifications(
      hubCon,
      'MarketingPackagePdfProcessedEvent',
      (data: PdfProcessedEvent) => {
        dispatch(setGeneratedFormDocuments(data));
      }
    );

    receiveNotifications(
      hubCon,
      'MarketingPackageUpdatedEvent',
      async (data: MarketingPackageUpdatedEvent) => {
        const authToken = await getAuthToken();
        // update the campaign to trigger the re-render
        if (!!authToken) {
          dispatch(
            updateCampaign(authToken, data.campaignId, FormConfigType.Marketing)
          );
        }
      }
    );

    dispatch(updateConnection(ConnectionState.Connected, connectionName));

    setHubConnection(previousConnections => {
      const newConnection = new Map(previousConnections);
      newConnection.set('marketing', hubCon);
      return newConnection;
    });
  };

  // Add a lock to prevent the browser tab from sleeping while it isn't opened
  useEffect(() => {
    if (navigator && navigator.locks && navigator.locks.request) {
      const promise = new Promise(res => {
        setLockResolver(res);
      });

      navigator.locks.request(
        'autopilot_shared_lock',
        {
          mode: 'shared',
        },
        () => {
          return promise;
        }
      );
    }

    return () => {
      if (lockResolver) {
        lockResolver();
      }
    };
  }, []);

  useEffect(() => {
    const keys = Object.keys(notificationState) as ConnectionName[];
    keys.forEach(key => {
      if (
        notificationState[key].connectionState === ConnectionState.Disconnected
      ) {
        console.error(key, 'AutoPilot disconnected.');
      }
    });
  }, [notificationState]);

  useEffect(() => {
    if (!userAccount) return;
    if (!hubConnection.get('campaigns')) attachEventListenersForCampaigns();
    if (!hubConnection.get('marketing')) attachEventListenersForMarketing();
  }, [userAccount, hubConnection]);

  return children;
};
