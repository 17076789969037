import {
  AgxButton,
  AgxColumn,
  AgxLabel,
  AgxRow,
  MultiOptionButtonOption,
} from '@urbanx/agx-ui-components';
import slugify from 'slugify';
import './FileList.scss';

interface Props {
  files: MultiOptionButtonOption[];
  extraClasses?: string;
  title?: string;
}
const FileList = (props: Props) => {
  const { files, title = 'Contract Documents', extraClasses = '' } = props;

  if (files.length === 0) <></>;

  return (
    <AgxColumn mediumGap fill centered>
      <AgxColumn mediumGap fill extraClasses={'fileListItemWithoutBorder'}>
        <AgxRow fill>
          <AgxLabel
            medium
            dataTestId={`agx-file-list-${title.toLowerCase().replace(' ', '-')}`}
          >
            {title}
          </AgxLabel>
        </AgxRow>
        {files.map((file, index) => (
          <AgxRow
            fill
            key={`${file.text}-${index}`}
            spaceBetween
            largeGap
            extraClasses={`fileListItem ${extraClasses} `}
          >
            <AgxLabel
              medium
              showEllipses
              noWrap
              dataTestId={`agx-file-list-${file.text.toLowerCase().replace(' ', '-')}`}
            >
              {file.text}
            </AgxLabel>
            <AgxButton
              id={`viewContractSubmissionPdfs-download-${slugify(file.text)}`}
              naked
              text="Download"
              extraClasses="downloadButtonStyle"
              onClick={() => (file.onClick ? file.onClick() : undefined)}
              dataTestId={`agx-file-list-button-${file.text.toLowerCase().replace(' ', '-')}`}
            />
          </AgxRow>
        ))}
      </AgxColumn>
    </AgxColumn>
  );
};

export default FileList;
