import {
  AustralianState,
  Form,
  FormConfigType,
  FormType,
  PageStatus,
} from '@urbanx/agx-ui-components';
import { AgxApi } from '../../helpers/http';
import { AppDispatch } from '../../store/store';
import { setAndShowErrorToast } from '../../store/config';
import { loadFormConfig } from '../../features/form/formReducer';
import { MarketingPackageModel } from './Types/Marketing';
import { SendFormSubmission } from 'Api/Campaigns/Types/types';

export const marketingApi = (authToken: string) =>
  new AgxApi('marketing', authToken);

export const getMarketingPackages = async (
  authToken: string,
  campaignId: string
) => {
  const { data: marketingPackages } = await marketingApi(authToken).get<
    MarketingPackageModel[]
  >('GetMarketingPackages', {
    campaignId,
  });

  return marketingPackages;
};

export const beginMarketingPackage = async (
  authToken: string,
  dispatch: AppDispatch,
  campaignId: string
) => {
  try {
    const { data: formConfig, status } = await marketingApi(
      authToken
    ).post<Form>('BeginMarketingPackage', {
      campaignId,
    });

    if ([200, 204].includes(status) && formConfig?.formId) {
      dispatch(loadFormConfig(formConfig));
      return { formId: formConfig.formId };
    } else {
      dispatch(setAndShowErrorToast('Failed to start new marketing package'));
    }
  } catch (err: any) {
    console.error(err);
    dispatch(setAndShowErrorToast(err.message));
    return err.message;
  }
};

export const loadFormMarketing = async (
  authToken: string,
  campaignId: string,
  formId: string
) => {
  try {
    const { data: form, status } = await marketingApi(authToken).get<Form>(
      'LoadMarketingPackageForm',
      {
        campaignId,
        formId,
      }
    );

    if (status === 200) {
      return { ...form, formConfigType: FormConfigType.Marketing };
    }
  } catch (err: any) {
    console.error(err);
  }

  return null;
};

export const getMarketingPackage = async (
  authToken: string,
  marketingPackageId: string
) => {
  try {
    const { data: marketingPackage, status } = await marketingApi(
      authToken
    ).get<MarketingPackageModel>('GetMarketingPackage', {
      marketingPackageId,
    });

    if (status === 200) {
      return marketingPackage;
    }
  } catch (err: any) {
    console.error(err);
  }
};

export const saveFormMarketing = async (
  authToken: string,
  campaignId: string,
  formId: string,
  formType: FormType,
  state: AustralianState,
  formData: any | null,
  pageStatuses: PageStatus[] | null
) => {
  try {
    const { status } = await marketingApi(authToken).post(
      'SaveMarketingPackage',
      {
        campaignId: campaignId,
        formId: formId,
        formType: formType,
        state: state,
        formData,
        pageStatuses,
      }
    );

    if (status === 200) {
      return true;
    }
  } catch (err: any) {
    console.error(err);
  }

  return false;
};

export const submitMarketingPackage = async (
  authToken: string,
  campaignId: string,
  formId: string,
  formType: FormType,
  formData: any | null
) => {
  try {
    const { data, status } = await marketingApi(authToken).post(
      'SubmitMarketingPackage',
      {
        campaignId,
        formId,
        formType,
        formData,
      }
    );

    if (status === 200) {
      return data;
    }
  } catch (err: any) {
    console.error(err);
  }

  return false;
};

export const loadMarketingPackageForm = async (
  authToken: string,
  formId: string
) => {
  try {
    const { data: form, status } = await marketingApi(authToken).get<Form>(
      'LoadMarketingPackageForm',
      {
        formId,
      }
    );

    if (status === 200) {
      return form;
    }
  } catch (err: any) {
    console.error(err);
  }

  return false;
};

export const sendMarketingPackage = async (
  authToken: string,
  sendFormSubmission: SendFormSubmission
) => {
  try {
    const { status } = await marketingApi(authToken).post(
      'SendMarketingPackage',
      sendFormSubmission
    );

    if (status === 200) {
      return true;
    }
  } catch (err: any) {
    console.error(err);
  }

  return false;
};

export const removeMarketingPackageSubmission = async (
  authToken: string,
  marketingPackageId: string,
  marketingPackageSubmissionId: string
) => {
  try {
    const { status } = await marketingApi(authToken).post(
      'RemoveMarketingPackageSubmission',
      {
        marketingPackageId,
        marketingPackageSubmissionId
      }
    );

    if (status === 200) {
      return true;
    }
  } catch (err: any) {
    console.error(err);
  }

  return false;
};
