import { AgxLabel, CampaignDetailModel } from '@urbanx/agx-ui-components';
import { CampaignsViewPageImageCard } from '../../../CampaignsViewPageImageCard/CampaignsViewPageImageCard';

interface GroupedCampaignProps {
  title?: string;
  campaigns: CampaignDetailModel[];
  onCampaignClick: (campaignId: string) => void;
  unread?: boolean;
}

export const GroupedCampaigns = ({
  title,
  campaigns,
  onCampaignClick,
  unread = false,
}: GroupedCampaignProps) => {
  if (campaigns.length === 0) return null;

  return (
    <>
      {title && <AgxLabel small>{title}</AgxLabel>}
      {campaigns.map((campaign, index) => (
        <CampaignsViewPageImageCard
          key={campaign.campaignId}
          onClick={() => onCampaignClick(campaign.campaignId)}
          campaign={campaign}
          unread={unread}
          index={index + 1}
        />
      ))}
    </>
  );
};
