import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { setAndShowErrorToast } from '../../store/config';
import { LoadingState } from '../../utils/loadingState';
import { AppDispatch } from '../../store/store';
import { MarketingItem, MarketingPackage } from 'Api/Marketing/Types/Marketing';
import { marketingApi } from 'Api/Marketing/marketingApi';

interface MarketingState {
  marketingPackages: {
    loadingState: LoadingState;
    packages: MarketingPackage[];
  };
  marketingItems: {
    loadingState: LoadingState;
    items: MarketingItem[];
  };
}

const initialState = {
  marketingPackages: {
    loadingState: LoadingState.NotLoaded,
    packages: [],
  },
  marketingItems: {
    loadingState: LoadingState.NotLoaded,
    items: [],
  },
} satisfies MarketingState as MarketingState;

const slice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {
    startLoadingMarketingPackages: state => {
      return {
        ...state,
        marketingPackages: {
          ...initialState.marketingPackages,
          loadingState: LoadingState.Loading,
        },
      };
    },
    marketingPackagesLoaded: (
      state,
      action: PayloadAction<MarketingPackage[]>
    ) => {
      return {
        ...state,
        marketingPackages: {
          packages: action.payload,
          loadingState: LoadingState.Loaded,
        },
      };
    },
    errorLoadingMarketingPackages: state => {
      return {
        ...state,
        marketingPackages: {
          ...initialState.marketingPackages,
          loadingState: LoadingState.Failed,
        },
      };
    },
    startLoadingMarketingItems: state => {
      return {
        ...state,
        marketingItems: {
          ...initialState.marketingItems,
          loadingState: LoadingState.Loading,
        },
      };
    },
    marketingItemsLoaded: (state, action: PayloadAction<MarketingItem[]>) => {
      return {
        ...state,
        marketingItems: {
          items: action.payload,
          loadingState: LoadingState.Loaded,
        },
      };
    },
    errorLoadingMarketingItems: state => {
      return {
        ...state,
        marketingItems: {
          ...initialState.marketingItems,
          loadingState: LoadingState.Failed,
        },
      };
    },
  },
});

export default slice.reducer;

const {
  startLoadingMarketingPackages,
  marketingPackagesLoaded,
  errorLoadingMarketingPackages,
  startLoadingMarketingItems,
  marketingItemsLoaded,
  errorLoadingMarketingItems,
} = slice.actions;

export const fetchMarketingPackageTemplates =
  (authToken: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoadingMarketingPackages());
      const { data: marketingPackages } = await marketingApi(authToken).get<
        MarketingPackage[]
      >('GetMarketingPackageTemplatesForAgent');

      dispatch(marketingPackagesLoaded(marketingPackages));

      return 'success';
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      dispatch(errorLoadingMarketingPackages());
      return err.message;
    }
  };

export const fetchMarketingItemTemplates =
  (authToken: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoadingMarketingItems());
      const { data: marketingItems } = await marketingApi(authToken).get<
        MarketingItem[]
      >('GetMarketingItemTemplatesForAgent');

      dispatch(marketingItemsLoaded(marketingItems));

      return 'success';
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      dispatch(errorLoadingMarketingItems());
      return err.message;
    }
  };
