import React, { useState, useEffect, useMemo } from 'react';
import {
  AgxRow,
  AgxLabel,
  AgxTextInput,
  AgxDatePicker,
  AgxColumn,
  Images,
  AgxBodyText,
  Option,
  AgxSegmentedPicker,
  AgxSelect,
} from '@urbanx/agx-ui-components';
import './DepositPayable.scss';
import SpecialClause from '../SpecialClause/SpecialClause';
import Deposit, { DefaultErrorState } from './Deposit';
import {
  FinancialConditionType,
  DepositType,
  DepositOptions,
  ConditionType,
  ErrorState,
  DepositPayableType,
  DayType,
} from './types';
import { Segment } from '@urbanx/agx-ui-components/dist/types/components/portal/SegmentedPicker/SegmentedPicker';

interface DepositPayableProps {
  id: string;
  label: string;
  onValueChanged: (value: any) => void;
  defaultValue: any;
  required?: boolean;
  validate?: boolean;
  error?: ErrorState;
}

const DAYS_BUSINESSDAYS_OPTIONS: Option[] = [
  { label: 'day(s)', value: DayType.Days },
  { label: 'business day(s)', value: DayType.BusinessDays },
];
const DAYS_FROM_OPTIONS: Option[] = [
  {
    value: FinancialConditionType.FinanceBeingSatisfied,
    label: 'finance being satisfied',
  },
  {
    value: FinancialConditionType.BuildingAndPestBeingSatisfied,
    label: 'building and pest being satisfied',
  },
  {
    value: FinancialConditionType.UponSatisfactionOfSpecialCondition,
    label: 'satisfaction of special condition',
  },
];

const ON_OPTIONS: Option[] = [
  { value: FinancialConditionType.OnSpecificDate, label: 'specific date' },
  ...DAYS_FROM_OPTIONS,
];

const DepositPayable: React.FC<DepositPayableProps> = ({
  id,
  label,
  onValueChanged,
  defaultValue,
  required = false,
  validate = false,
  error = DefaultErrorState,
}) => {
  const [selectedCondition, setSelectedCondition] =
    useState<FinancialConditionType>(defaultValue?.condition);

  const [depositValue, setDepositValue] = useState({
    depositType: defaultValue?.depositType,
    depositValueType: defaultValue?.depositValueType ?? DepositOptions[0].value,
    amount: defaultValue?.amount,
    percent: defaultValue?.percent,
    daysFromDate: defaultValue?.daysFromDate,
    referenceDate: defaultValue?.referenceDate,
    conditionType: defaultValue?.conditionType,
    condition: selectedCondition,
    customSpecialCondition: defaultValue?.customSpecialCondition,
    dayType: defaultValue?.dayType ?? DayType.Days,
    depositPayable: defaultValue?.depositPayable || DepositPayableType.On,
  });
  const [selectedSegment, setSelectedSegment] = useState<DepositPayableType>(
    depositValue.depositPayable
  );

  const conditionTypeCheck =
    id === DepositType.Initial
      ? ConditionType.Contract
      : ConditionType.Unconditional;

  const isInitialDeposit = depositValue.depositType === DepositType.Initial;
  const hasAmountOrPercent =
    depositValue.amount !== undefined || depositValue.percent !== undefined;
  const isConditionUnchecked = !depositValue.condition;

  const segments: Segment[] = [
    {
      title: 'on...',
      mobileTitle: 'on...',
      isSelected: selectedSegment === DepositPayableType.On,
      onSelect: () => onSegmentChange(DepositPayableType.On),
    },
    {
      title: '# days from...',
      mobileTitle: '# days from...',
      isSelected: selectedSegment === DepositPayableType.NDaysFrom,
      onSelect: () => onSegmentChange(DepositPayableType.NDaysFrom),
    },
  ];

  const showDepositPayableValidation = () => {
    if (isInitialDeposit) {
      return validate && isConditionUnchecked && required;
    } else {
      return hasAmountOrPercent ? validate && isConditionUnchecked : false;
    }
  };

  const showBalanceDepositValidation = () => {
    return (
      !isInitialDeposit &&
      ((isConditionUnchecked && hasAmountOrPercent) ||
        (!isConditionUnchecked && !hasAmountOrPercent))
    );
  };

  const handleOnConditionChange = (value: FinancialConditionType) => {
    setSelectedCondition(value);
    setDepositValue({
      ...depositValue,
      condition: value,
      customSpecialCondition:
        value !== FinancialConditionType.UponSatisfactionOfSpecialCondition
          ? undefined
          : depositValue.customSpecialCondition,
    });
  };

  const handleFromDaysConditionChange = (value: FinancialConditionType) => {
    setSelectedCondition(value);
    setDepositValue({
      ...depositValue,
      condition: value,
      customSpecialCondition:
        value !== FinancialConditionType.UponSatisfactionOfSpecialCondition
          ? undefined
          : depositValue.customSpecialCondition,
    });
  };

  const onSegmentChange = (value: DepositPayableType) => {
    if (value === DepositPayableType.On) {
      /**
       * check if it if for initial deposit or Balance deposit
       * for initial deposit: dropdown options are different and Contract date gets selected
       * for balance deposit: clear the selection to choose an option
       */
      if (conditionTypeCheck === ConditionType.Contract)
        setSelectedCondition(FinancialConditionType.OnDate);
      else setSelectedCondition(FinancialConditionType.Empty);
    } else {
      /**
       * check if it if for initial deposit or Balance deposit
       * for initial deposit: dropdown options are different and Contract date gets selected
       * for balance deposit: Unconditional date gets selected
       */
      setSelectedCondition(FinancialConditionType.OnDate);
    }

    setSelectedSegment(value);

    // make sure to clear any previous selections
    setDepositValue({
      ...depositValue,
      depositPayable: value,
      daysFromDate: null,
      referenceDate: null,
      dayType: DayType.Days,
      customSpecialCondition: undefined,
    });
  };

  useEffect(() => {
    if (depositValue) {
      let condition = depositValue.condition;
      if (isInitialDeposit && !depositValue?.condition) {
        setSelectedCondition(FinancialConditionType.OnDate);
      }
      onValueChanged({
        id,
        value: {
          ...depositValue,
          condition: condition,
          depositType: id,
          conditionType: conditionTypeCheck,
        },
      });
    }
  }, [depositValue]);

  useEffect(() => {
    if (selectedCondition) {
      setDepositValue({ ...depositValue, condition: selectedCondition });
    }
  }, [selectedCondition]);

  const filterSelectedOption = (options: Option[]) => {
    return options.filter(option => option.value !== selectedCondition);
  };

  const depositPayableOnOptions = useMemo(() => {
    return conditionTypeCheck === ConditionType.Contract
      ? [
          {
            value: FinancialConditionType.OnDate,
            label: 'Contract date',
          },
          ...ON_OPTIONS,
        ]
      : [
          {
            value: FinancialConditionType.Empty,
            label: 'select option',
          },
          {
            value: FinancialConditionType.OnDate,
            label: 'unconditional date',
          },
          ...ON_OPTIONS,
        ];
  }, [conditionTypeCheck]);

  const depositPayableDaysFromOptions = useMemo(() => {
    return conditionTypeCheck === ConditionType.Contract
      ? [
          {
            value: FinancialConditionType.OnDate,
            label: 'Contract date',
          },
          ...DAYS_FROM_OPTIONS,
        ]
      : [
          {
            value: FinancialConditionType.OnDate,
            label: 'unconditional date',
          },
          ...DAYS_FROM_OPTIONS,
        ];
  }, [conditionTypeCheck]);

  return (
    <AgxColumn veryLargeGap>
      <Deposit
        key={id}
        id={id}
        label={label}
        onValueChanged={({ value }) => {
          setDepositValue({
            ...depositValue,
            depositValueType: value.depositValueType,
            amount: value.amount,
            percent: value.percent,
          });
        }}
        defaultValue={depositValue}
        required={required}
        validate={validate}
        balanceDepositValidation={showBalanceDepositValidation()}
        error={error}
        decimalPoints={0}
      />
      <AgxColumn mediumGap fill>
        <AgxRow fill spaceBetween>
          <AgxLabel medium>{label} Payable</AgxLabel>
        </AgxRow>
        <AgxSegmentedPicker
          segments={segments}
          extraClasses={'segmentPickerStyle'}
        />
        {selectedSegment === DepositPayableType.On && (
          <AgxSelect
            options={filterSelectedOption(depositPayableOnOptions)}
            id={`onselect-${id}`}
            defaultValue={depositPayableOnOptions.find(
              option => option.value === selectedCondition
            )}
            onValueChanged={({ value }) =>
              handleOnConditionChange(value as FinancialConditionType)
            }
            hideOptionalLabel
            required
            small
          />
        )}
        {selectedSegment === DepositPayableType.NDaysFrom && (
          <AgxRow mediumGap wrap>
            <AgxTextInput
              id="numberOfDays"
              numberOnly
              noOptionalLabel
              maxLength={2}
              defaultValue={depositValue.daysFromDate}
              extraClasses="depositPayableTextInput"
              onInputValueChange={({ value }) =>
                setDepositValue({
                  ...depositValue,
                  daysFromDate: value,
                })
              }
              error={
                validate &&
                (hasAmountOrPercent || showBalanceDepositValidation()) &&
                !depositValue.daysFromDate
                  ? 'Enter days'
                  : ''
              }
            />
            <AgxRow>
              <AgxSelect
                options={DAYS_BUSINESSDAYS_OPTIONS}
                id={`ondaysselect-${id}`}
                defaultValue={DAYS_BUSINESSDAYS_OPTIONS.find(
                  option => option.value === depositValue.dayType
                )}
                onValueChanged={({ value }) =>
                  setDepositValue({
                    ...depositValue,
                    dayType: value,
                  })
                }
                hideOptionalLabel
                required
                small
              />
            </AgxRow>
            <AgxRow extraClasses={'paddingTop20'}>
              <AgxLabel small>from</AgxLabel>
            </AgxRow>
            <AgxRow extraClasses={'flexGrow1'}>
              <AgxSelect
                options={filterSelectedOption(depositPayableDaysFromOptions)}
                id={`onselect-${id}`}
                defaultValue={depositPayableDaysFromOptions.find(
                  option => option.value === selectedCondition
                )}
                onValueChanged={({ value }) =>
                  handleFromDaysConditionChange(value as FinancialConditionType)
                }
                hideOptionalLabel
                required
                small
              />
            </AgxRow>
          </AgxRow>
        )}
        {selectedCondition ===
          FinancialConditionType.UponSatisfactionOfSpecialCondition && (
          <SpecialClause
            key={`${conditionTypeCheck}SpecialClause`}
            id={`${conditionTypeCheck}SpecialClause`}
            validate={validate}
            defaultValue={depositValue.customSpecialCondition}
            onSelectedClause={(value: any) =>
              setDepositValue({
                ...depositValue,
                customSpecialCondition: value,
              })
            }
          />
        )}
        {selectedCondition === FinancialConditionType.OnSpecificDate && (
          <AgxDatePicker
            key={`${conditionTypeCheck}OnSpecificDate`}
            id={`${conditionTypeCheck}OnSpecificDate`}
            onValueChanged={({ value }) =>
              setDepositValue({
                ...depositValue,
                referenceDate: value,
              })
            }
            required
            noHeader
            noOptionalLabel
            defaultValue={depositValue.referenceDate}
            date
            stretch
            error={
              validate && !depositValue.referenceDate
                ? 'Enter a date'
                : undefined
            }
          />
        )}
      </AgxColumn>
      {showDepositPayableValidation() && (
        <div className={'errorMessage'}>
          <Images.AlertCircle />
          <AgxBodyText small extraClasses={'error'}>
            {'Select an option'}
          </AgxBodyText>
        </div>
      )}
    </AgxColumn>
  );
};

export default DepositPayable;
