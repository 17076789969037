import {
  EntityTimePeriodStatistics,
  PartnershipStatistics,
} from '@urbanx/agx-ui-components/dist/types/components/portal/Reporting/types';

export interface GetAgentStatisticsResponse {
  agentStatistics: AgentStatisticsModel;
  leaderBoardStatistics: LeaderBoardStatisticsModel;
  partnershipStatistics: PartnershipStatistics | null;
}

export interface AgentStatisticsModel {
  agentOrTeamStatistics: AgentOrTeamStatistics | null;
  agencyStatistics: EntityStatistics | null;
  error: string | null;
}

export interface AgentOrTeamStatistics extends EntityStatistics {
  isTeamStatistics: boolean;
}

export interface EntityStatistics {
  title: string;
  annualStatistics: EntityTimePeriodStatistics;
  monthlyStatistics: EntityTimePeriodStatistics;
  quarterlyStatistics: EntityTimePeriodStatistics;
}

export enum TimePeriodType {
  Month = 'Month',
  Quarter = 'Quarter',
  Annual = 'Year',
}

export interface LeaderBoardStatisticsModel {
  personalLeaderBoardStatistics: LeaderBoardEntityTypeStatisticsModel;
  agencyLeaderBoardStatistics: LeaderBoardEntityTypeStatisticsModel | null;
}

export interface LeaderBoardEntityTypeStatisticsModel {
  leaderBoardAnnualStats: LeaderBoardStatsModel;
  leaderBoardQuarterlyStats: LeaderBoardStatsModel;
  leaderBoardMonthlyStats: LeaderBoardStatsModel;
}

export interface TopPerformersStatsModel {
  personalPosition: BasePositionModel | null;
  leaderBoardModels: BasePositionModel[];
}

export interface LeaderBoardStatsModel {
  personalPosition: LeaderBoardPositionModel | null;
  leaderBoardModels: LeaderBoardPositionModel[];
}

export interface BasePositionModel {
  rank: number;
  name: string | null;
  isPersonalEntity: boolean;
  photoUri: string | null;
  backgroundColour: string | null;
  backgroundTextColour: string | null;
}

export interface LeaderBoardPositionModel extends BasePositionModel {
  amount: number;
}

export enum EntityType {
  Individual,
  Team,
  Agency,
}

export interface TopPerformersModel {
  topPerformers: TopPerformersStatsModel;
  topPartners: TopPerformersStatsModel;
}
