import { useAppSelector } from 'hooks/useAppSelector';
import { EntityStatistics, TimePeriodType } from '../../../types/Reporting';
import {
  EntityTypes,
  ReportingDashboardState,
} from '../reportingDashboardReducer';
import { useMemo } from 'react';
import {
  PartnershipStatistics,
  EntityTimePeriodStatistics,
} from '@urbanx/agx-ui-components/dist/types/components/portal/Reporting/types';

export const usePersonalStatistics = (
  entityType: string | null,
  timePeriod: string | null,
  partnershipYearPeriod: string
): [
  PartnershipStatistics | EntityTimePeriodStatistics | null,
  string | null,
] => {
  const reportingDashboardState: ReportingDashboardState = useAppSelector(
    state => state.reportingDashboard
  );

  const { stats } = reportingDashboardState;

  const [currentEntityInfo, title] = useMemo(() => {
    if (!stats || entityType === null || timePeriod === null)
      return [null, null];

    let entityStats: EntityStatistics | null;

    switch (entityType) {
      // Individual / Team
      case EntityTypes[0].value:
        entityStats = stats.agentStatistics
          .agentOrTeamStatistics as EntityStatistics;
        break;
      // Agency
      case EntityTypes[1].value:
        entityStats = stats.agentStatistics.agencyStatistics;
        break;
      // Partnership
      case EntityTypes[2].value:
        return [stats.partnershipStatistics, 'Partnership stats'];
      default:
        return [null, null];
    }

    if (entityStats === null) return [null, null];

    switch (timePeriod) {
      case TimePeriodType.Month:
        return [entityStats.monthlyStatistics, entityStats.title];
      case TimePeriodType.Quarter:
        return [entityStats.quarterlyStatistics, entityStats.title];
      case TimePeriodType.Annual:
        return [entityStats.annualStatistics, entityStats.title];
      default:
        return [null, null];
    }
  }, [stats, entityType, timePeriod, partnershipYearPeriod]);

  return [currentEntityInfo, title];
};
