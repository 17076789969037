import { ConnectionName, NotificationState } from "store/notificationsReducer";

export enum ConnectionState {
  NotConnected = 'NotConnected',
  Connecting = 'Connecting',
  Connected = 'Connected',
  Reconnecting = 'Reconnecting',
  Disconnected = 'Disconnected',
}

export const isNotificationDisconnected = (notificationState: NotificationState) => {
    const keys = Object.keys(notificationState) as ConnectionName[];
    let isConnectionDisconnected = false;
    keys.forEach(key => {
      if (
        notificationState[key].connectionState === ConnectionState.Disconnected
      ) {
        isConnectionDisconnected = true;
      }
    });

    return isConnectionDisconnected;
  };
