import clsx from 'clsx';
import {
  AgxCaption,
  AgxColumn,
  AgxLabel,
  AgxRow,
  CampaignStage,
  CampaignDetailModel,
} from '@urbanx/agx-ui-components';
import { cleanTwoLineAddressWithoutState } from 'utils/formatAddress';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { getMomentFromNow } from 'utils/utcDateFromNow';
import { useSelectedCampaign } from 'hooks/useSelectedCampaign';
import placeHolderImage from '../../../../../assets/images/placeholder-property.png';
import { getEnumValue } from 'helpers/enumHelper';
import './CampaignsViewPageImageCard.scss';

export const CampaignsViewPageImageCard = ({
  onClick,
  campaign,
  index,
  unread = false,
}: {
  onClick: () => void;
  campaign: CampaignDetailModel;
  unread?: boolean;
  index: number;
}) => {
  const {
    campaignId,
    stage,
    address,
    propertyImageUrls,
    lastUpdated,
    completionState,
  } = campaign;

  const [addressLineOne, addressLineTwo] =
    cleanTwoLineAddressWithoutState(address);

  const image = propertyImageUrls?.thumbnail;

  const selectedCampaign = useSelectedCampaign();

  const isSelected = selectedCampaign?.campaignId === campaignId;

  const isMobileSize = ScreenSize() === Breakpoints.Mobile;
  const isTabletSize =
    ScreenSize() === Breakpoints.Tablet_Landscape ||
    ScreenSize() === Breakpoints.Tablet_Portrait;

  const cardClasses = clsx(
    'propertyViewImageCard',
    'gapOther',
    'cursorPointer',
    isSelected && 'selected',
    unread && 'unread'
  );

  return (
    <AgxRow onClick={onClick} extraClasses={cardClasses} fill centered>
      <img
        className={completionState.completionType ? 'archived' : ''}
        src={image ?? placeHolderImage}
        alt="Property"
      />
      <AgxColumn
        spaceBetween
        fill
        centerJustified
        extraClasses="propertyCardDetails"
      >
        <AgxCaption
          dataTestId={`agx-campaignStage-${index}`}
          small={isMobileSize || isTabletSize}
          extraClasses={`${unread ? 'boldText' : 'lightText'}`}
        >
          {getEnumValue(CampaignStage, stage)}
        </AgxCaption>
        <AgxCaption
          dataTestId={`agx-campaignDate-${index}`}
          small={isMobileSize || isTabletSize}
          extraClasses="lightText date"
        >
          {getMomentFromNow(lastUpdated.toString())}
          {unread && (
            <AgxCaption extraClasses="circle">
              <></>
            </AgxCaption>
          )}
        </AgxCaption>

        <AgxLabel
          extraClasses={'campaignListAddress'}
          dataTestId={`agx-campaignAddress-${index}`}
        >
          {addressLineOne} <br /> {addressLineTwo}
        </AgxLabel>
      </AgxColumn>
    </AgxRow>
  );
};
