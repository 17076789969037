import React from 'react';
import {
  AgxBodyText,
  AgxButton,
  AgxRow,
  AgxColumn,
  AgxHeader,
} from '@urbanx/agx-ui-components';
import { Breakpoints, ScreenSize } from 'utils/screen';
import QuestionMark from '../../assets/icons/question-mark-circle-outline.svg?react';
import './NoPropertyFound.scss';

interface NoPropertyFoundProps {
  goToSearch: () => void;
  goToManualAddress: () => void;
}

const NoPropertyFound: React.FC<NoPropertyFoundProps> = ({
  goToSearch,
  goToManualAddress,
}) => {
  const isDesktop = ScreenSize() === Breakpoints.Desktop;

  return (
    <AgxColumn
      fill
      centerJustified
      centered
      extraLargeGap
      extraClasses="noPropertyFound"
    >
      <QuestionMark width={48} height={48} />
      <AgxHeader size={2} inverse centered>
        Hmm, this property does not exist in RP Data
      </AgxHeader>
      <AgxBodyText medium inverse centered>
        Search again, or create agreement manually
      </AgxBodyText>
      {isDesktop ? (
        <AgxRow largeGap>
          <AgxButton
            primary
            large
            dark
            text="Search again"
            onClick={goToSearch}
          />
          <AgxButton
            hollow
            large
            dark
            text="Create agreement manually"
            onClick={goToManualAddress}
          />
        </AgxRow>
      ) : (
        <AgxColumn largeGap fill>
          <AgxButton
            primary
            large
            dark
            text="Search again"
            wide={ScreenSize() === Breakpoints.Mobile}
            onClick={goToSearch}
          />
          <AgxButton
            hollow
            large
            dark
            text="Create agreement manually"
            wide={ScreenSize() === Breakpoints.Mobile}
            onClick={goToManualAddress}
          />
        </AgxColumn>
      )}
    </AgxColumn>
  );
};

export default NoPropertyFound;
