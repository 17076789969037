import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, FormConfigType } from '@urbanx/agx-ui-components';
import { useAzureAuth } from './useAzureAuth';
import { useAppDispatch } from './useAppDispatch';
import { loadFormCampaign } from 'Api/Campaigns/campaignsApi';
import { setAndShowErrorToast } from '../store/config';
import { loadFormConfig } from '../features/form/formReducer';
import { loadFormMarketing } from 'Api/Marketing/marketingApi';

export const useFormLoader = () => {
  const [, getAuthToken] = useAzureAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const errorMessage = 'Failed to load form';
  const loadForm = useCallback(
    async (
      campaignId: string,
      formId: string,
      formConfigType: FormConfigType
    ) => {
      if (!getAuthToken) return;

      const authToken = await getAuthToken();

      if (!authToken) {
        dispatch(setAndShowErrorToast(errorMessage));
        return;
      }
      let form: Form | null = null;
      switch (formConfigType) {
        case FormConfigType.Campaign: {
          form = await loadFormCampaign(authToken, campaignId, formId);
          break;
        }
        case FormConfigType.Marketing: {
          form = await loadFormMarketing(authToken, campaignId, formId);
          break;
        }
      }

      if (!form) {
        dispatch(setAndShowErrorToast(errorMessage));
        return;
      }

      dispatch(loadFormConfig(form));
      navigate('/form');
    },
    [getAuthToken, dispatch, navigate]
  );

  const loadFormWithConfig = useCallback(
    async (form: Form) => {
      dispatch(loadFormConfig(form));
      navigate('/form');
    },
    [dispatch, navigate]
  );

  return {
    loadForm,
    loadFormWithConfig,
  };
};
