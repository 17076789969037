import axios, { AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';

interface RequestParameters {
  [key: string]: string | string[] | number | boolean
}

export class AgxApi {
  private baseUrl: string;
  private authToken: string;
  private headers: {};

  constructor(
    service: string,
    authToken: string,
    uri: string | null = null,
    headers: { [key: string]: string } = {}
  ) {
    const baseUri = uri ?? import.meta.env.VITE_REACT_APP_AGENTX_BASE_API;

    this.baseUrl = `${baseUri}/${service}/`;

    this.authToken = authToken;

    headers['x-session-id'] =
      sessionStorage.getItem(http.sessionName) ?? uuidv4();
    this.headers = headers;
  }

  get<T>(
    url: string,
    queryParameters: any = {},
    signal?: AbortSignal,
    onUploadProgress?: (progressEvent: any) => void
  ) {
    return axios.get<T>(
      `${this.baseUrl}${url}`,
      this.createParameters(queryParameters, signal, onUploadProgress)
    );
  }

  post<T>(
    url: string,
    body?: any,
    queryParameters: any = {},
    signal?: AbortSignal,
    onUploadProgress?: (progressEvent: any) => void
  ) {
    return axios.post<T>(
      `${this.baseUrl}${url}`,
      body,
      this.createParameters(queryParameters, signal, onUploadProgress)
    );
  }

  put<T>(
    url: string,
    body?: any,
    signal?: AbortSignal,
    onUploadProgress?: (progressEvent: any) => void
  ) {
    return axios.put<T>(
      `${this.baseUrl}${url}`,
      body,
      this.createParameters(undefined, signal, onUploadProgress)
    );
  }

  patch<T>(
    url: string,
    body?: any,
    signal?: AbortSignal,
    onUploadProgress?: (progressEvent: any) => void
  ) {
    return axios.patch<T>(
      `${this.baseUrl}${url}`,
      body,
      this.createParameters(undefined, signal, onUploadProgress)
    );
  }

  delete<T>(
    url: string,
    signal?: AbortSignal,
    onUploadProgress?: (progressEvent: any) => void
  ) {
    return axios.delete<T>(
      `${this.baseUrl}${url}`,
      this.createParameters(undefined, signal, onUploadProgress)
    );
  }

  createParameters = (
    queryParameters: any = {},
    signal?: AbortSignal,
    onUploadProgress?: (progressEvent: any) => void
  ) => {
    let config: AxiosRequestConfig = {
      headers: {
        Authorization: 'Bearer ' + this.authToken,
        ...this.headers,
      },
      params: {
        ...queryParameters,
      },
      paramsSerializer: (params: RequestParameters): string => {
        return Object.keys(params)
          .map((key) => {
            const value = params[key];
            return Array.isArray(value) ?
              value.map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&') :
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          })
          .join('&');
      },
      signal: signal,
      onUploadProgress: onUploadProgress,
    };

    return config;
  };
}

export const http = {
  get<T>(url: string) {
    return axios.get<T>(url);
  },
  post<T>(url: string, body?: any) {
    return axios.post<T>(url, body);
  },
  put<T>(url: string, body?: any) {
    return axios.put<T>(url, body);
  },
  patch<T>(url: string, body?: any) {
    return axios.patch<T>(url, body);
  },
  delete<T>(url: string) {
    return axios.delete<T>(url);
  },
  sessionName: 'autopilot-sessionId',
};
