import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { IPublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { User } from '../types/Auth';

const logoutOnAuthErrors = [
  'invalid_grant',
  'interaction_required',
  'no_tokens_found',
];

export const Roles = {
  Director: 'director',
  SalesAgent: 'salesagent',
  Admin: 'admin',
  Staff: 'staff',
  SuperAdmin: 'superadmin',
};

export const useAzureAuth = (): [
  User | undefined,
  () => Promise<string | void>,
] => {
  const { accounts, instance } = useMsal();
  const msalAccount = accounts[0];
  const [userAccount, setUserAccount] = useState<User | undefined>(undefined);

  useEffect(() => {
    if (msalAccount) {
      const tokenClaims = msalAccount?.idTokenClaims;
      setUserAccount({
        firstName: tokenClaims?.given_name as string,
        lastName: tokenClaims?.family_name as string,
        fullName:
          `${tokenClaims?.given_name} ${tokenClaims?.family_name}` as string,
        agent: tokenClaims?.agent as string,
        agency: tokenClaims?.agency as string,
        roles: tokenClaims?.roles as string[],
      });
    }
  }, [msalAccount]);

  async function getAuthToken(): Promise<string | void> {
    const request: RedirectRequest = {
      scopes: [`${import.meta.env.VITE_REACT_APP_AGENTX_AUTH_APP_URI}/openid`],
      account: msalAccount,
    };

    try {
      if (msalAccount) {
        return await loginSilent(instance, request);
      } else {
        return await loginWithRedirect(instance, request);
      }
    } catch (err: any) {
      if (logoutOnAuthErrors.includes(err.errorCode)) {
        await instance.logoutRedirect();
      } else {
        console.error(err);
      }
    }
  }

  async function loginWithRedirect(
    instance: IPublicClientApplication,
    request: RedirectRequest
  ) {
    await instance.acquireTokenRedirect(request);
  }

  async function loginSilent(
    instance: IPublicClientApplication,
    request: RedirectRequest
  ) {
    const silentTokenResponse = await instance.acquireTokenSilent(request);

    if (!silentTokenResponse.account) {
      return await loginWithRedirect(instance, request);
    }
    return silentTokenResponse.accessToken;
  }

  return [userAccount, getAuthToken];
};
